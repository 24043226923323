import React from "react";
import { LayoutOutlined } from "@ant-design/icons";
import { Breadcrumb, Space } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

type BreadCrumbNames = {
  [key: string]: string;
};

const breadCrumbNames: BreadCrumbNames = {
  dashboard: "Dashboard",
  customers: "Customers",
  suppliers: "Suppliers",
  "all-customers": "All Customers",
  "all-suppliers": "All Suppliers",
  "cash-book": "Cash Book",
  "stock-book": "Stock Book",
  "bill-book": "Bill Book",
  "staff-book": "Staff Book",
  "notifications": "Notifications",
};

const BreadCrumbs: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const allPathNames = location.pathname.split("/").filter((x) => x);

  let title = breadCrumbNames[allPathNames[0]]
    ? breadCrumbNames[allPathNames[0]]
    : "";
  title += breadCrumbNames[allPathNames[1]]
    ? ` / ${breadCrumbNames[allPathNames[1]]}`
    : "";

  if (location.pathname === '/') {
    title = `Dashboard`
  }

  return (
    <Space>
      <LayoutOutlined style={{ marginRight: 10 }} />
      <Breadcrumb items={[{ title: title }]} />
    </Space>
  );
};

export default BreadCrumbs;
