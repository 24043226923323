import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCustomers,
  addCustomer,
  updateCustomer,
  deleteCustomer,
} from "./customer.service";
import { RootState } from "store/store";
import { CustomerI } from "types";

export interface AllCustomersI {
  data: CustomerI[];
  page: number;
  limit: number;
  hasNext: boolean;
  totalPages: number;
}

export interface CustomersStoreI {
  error: string | null;
  loading: boolean;
  allCustomers: AllCustomersI | null;
}

const initialState: CustomersStoreI = {
  loading: false,
  error: null,
  allCustomers: {
    data: [],
    page: 1,
    limit: 10,
    hasNext: false,
    totalPages: 1,
  },
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // setTodos: (state, action: PayloadAction<TodosI[]>) => {
    //   state.data = action.payload;
    // },
    // logoutUser: () => {
    //   return initialState;
    // },
  },
  extraReducers: (builder) => {
    builder
      // get All Customers
      .addCase(getAllCustomers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.allCustomers = action.payload.result;
      })
      .addCase(getAllCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add New Customers
      .addCase(addCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.allCustomers = {
          ...state.allCustomers,
          data: [
            action.payload.result.customer,
            ...(state.allCustomers?.data as CustomerI[]),
          ],
        } as AllCustomersI;
      })
      .addCase(addCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Customer
      .addCase(updateCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.allCustomers = {
          ...state.allCustomers,
          data: state.allCustomers?.data.map((user) =>
            user.id === action.payload.result.customer.id
              ? action.payload.result.customer
              : user,
          ) as CustomerI[],
        } as AllCustomersI;
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Customer
      .addCase(deleteCustomer.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.allCustomers = {
          ...state.allCustomers,
          data: state.allCustomers?.data.filter(
            (user) => user.id !== action.payload.deletedId,
          ) as CustomerI[],
        } as AllCustomersI;
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = customerSlice.actions;

export const customerState = (state: RootState) => state.customer;

export default customerSlice.reducer;
