import { useEffect, useRef, useState } from "react";
import { Dropdown, Table, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { TableHeader } from "components/Common";
import { ChevronDown } from "assets/icons";
import { CASHBOOK_COLUMNS, CUSTOMER_ACTION_ITEMS as items } from "const";
import { RootState, useAppDispatch, useAppSelector } from "store/store";
import { getAllCashbooks } from "store/features/cashbook/cashbook.service";
import { CashbookI } from "types";

interface CashbookTableI {
  onActionPress?: (type: { key: string }, data?: any) => void;
}

const CashbookTable = ({ onActionPress }: CashbookTableI) => {
  const dispatch = useAppDispatch();

  const tableContainerRef = useRef(null);
  const [type] = useState<string>("supplier");
  const { loading, allCashBooks } = useAppSelector(
    (state: RootState) => state.cashbook
  );
  const [activeRow, setActiveRow] = useState<CashbookI | null>(null);

  useEffect(() => {
    dispatch(getAllCashbooks({ type, page: 1, limit: 10 }));
  }, [dispatch]);

  const menuProps = {
    items,
    onClick: (type: { key: string }) => {
      onActionPress && onActionPress(type, activeRow);
    },
  };

  const TABLE_COLUMNS: ColumnsType<CashbookI> = [
    {
      title: "Sr.",
      key: "serial",
      render: (text, record, index) => {
        const current = allCashBooks?.page || 1;
        const pageSize = allCashBooks?.limit || 10;
        const sr = (current - 1) * pageSize + index + 1;
        return sr;
      },
    },
    ...CASHBOOK_COLUMNS,
    {
      title: "Action",
      key: "action",
      render: (props) => (
        <Dropdown
          menu={menuProps as any}
          trigger={"click" as any}
          onOpenChange={(open: boolean) => {
            if (open) {
              setActiveRow(props);
            }
          }}
        >
          <Button className="action-btn">
            Actions
            <img src={ChevronDown} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const querySearchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    dispatch(
      getAllCashbooks({
        type,
        page: 1,
        limit: 10,
        search: e.currentTarget.value,
      })
    );
  };

  return (
    <div className="page-padding display-center-col">
      <div className="table-container">
        <TableHeader
          onChange={querySearchHandler}
          data={allCashBooks?.data}
          tableContainerRef={tableContainerRef}
        />
        <div ref={tableContainerRef} className="overflow-y-auto">
          <Table
            loading={loading}
            columns={TABLE_COLUMNS}
            dataSource={allCashBooks?.data}
            className="customer-table"
            rowKey={(record) => record.id}
            pagination={{
              pageSize: allCashBooks?.limit,
              total: allCashBooks?.totalPages,
              onChange: (page, pageSize) => {
                dispatch(getAllCashbooks({ type, page, limit: pageSize }));
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { CashbookTable };
