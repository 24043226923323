import { FallOutlined, RiseOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { ReactNode } from "react";

const StatsCardWidget = ({
  label,
  value,
  change,
  icon,
}: {
  label: string;
  value: number;
  change?: { value: number; isPositive: boolean } | null;
  icon?: ReactNode | null
}) => {
  return (
    <div className="flex-1 bg-greyBase p-6 rounded-2xl border">
      <h4 className="text-xl">{label}</h4>
      <div className="mt-4 flex justify-between items-center">
        <h1 className="font-bold text-3xl">{value}</h1>

        <div className="flex items-center gap-2">
          {icon} 
          {/* {change && (
            <p
              className={`text-base ${
                change?.isPositive ? "text-green" : "text-error"
              }`}
            >
              {(change?.value).toFixed(2) + "%"}
            </p>
          )} */}
          {/* {change && (
            <>
              {change?.isPositive ? (
                <RiseOutlined className="text-green" />
              ) : (
                <FallOutlined className="text-error" />
              )}
            </>
          )} */}
        </div>
      </div>
    </div>
  );
};

export { StatsCardWidget };
