import { PlusOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Header } from "components/Common";
import { Dispatch, SetStateAction } from "react";

type CashbookHeaderI = {
  setOpenCashInModal: Dispatch<SetStateAction<boolean>>;
  setOpenCashOutModal: Dispatch<SetStateAction<boolean>>;
};

const CashbookHeader = ({
  setOpenCashInModal,
  setOpenCashOutModal,
}: CashbookHeaderI) => {
  return (
    <Header heading="Cashbook">
      <div className="cashbook-header-btns">
        <Button
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          onClick={() => setOpenCashInModal(true)}
        >
          Add Cashbook
        </Button>
        {/* <Button
          type="primary"
          size="large"
          icon={<UsergroupAddOutlined />}
          onClick={() => setOpenCashInModal(true)}
        >
          Cash In
        </Button> */}
        {/* <Button
          type="primary"
          size="large"
          icon={<UsergroupAddOutlined />}
          onClick={() => setOpenCashOutModal(true)}
        >
          Cash Out
        </Button> */}
      </div>
    </Header>
  );
};

export { CashbookHeader };
