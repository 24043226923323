import { DownloadOutlined } from "@ant-design/icons";
import { Button, Modal, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { BILL_COLUMNS as items } from "const";
import { useRef } from "react";
import { BillBookI } from "types";
import { downloadDivAsImage } from "utils/html2canvas";

type BillModalPropTypes = {
  open: boolean;
  onCancel: () => void;
  data: BillBookI;
};

const formatDate = (datetimeString: string) => {
  const options = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const formattedDate = new Date(datetimeString).toLocaleDateString(
    "en-US",
    options as any
  );

  return formattedDate;
};

const BillModal = ({ open, onCancel, data }: BillModalPropTypes) => {
  const billDivRef = useRef(null);

  const BILL_COLUMNS: ColumnsType<BillBookI> = [
    {
      title: "Sr.",
      key: "serial",
      render: (text, record, index) => index + 1,
    },
    ...(items as any),
  ];

  const downloadDiv = async () => {
    downloadDivAsImage(billDivRef, `Bill-${data.billNo}_${data.id}`);
  };

  return (
    <Modal
      title={null}
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={668}
      footer={[
        <Button
          size="large"
          type="primary"
          onClick={downloadDiv}
          icon={<DownloadOutlined />}
        >
          Download
        </Button>,
      ]}
    >
      <div className="add-item-modal" ref={billDivRef}>
        <h1 className="font-InterBold text-xl">{`Bill # ${data.billNo}`}</h1>
        <p className="text-grey mb-4">{formatDate(data.date)}</p>

        <div className="w-full">
          <Table
            columns={BILL_COLUMNS as any}
            dataSource={data.item}
            pagination={false}
          />
        </div>

        <div className="w-full">
          <h1 className="font-InterSemiBold text-base mt-2 mb-4">
            Total Amount:
          </h1>

          <p>Rs. {data.amount}</p>
        </div>
        <div className="w-full">
          <h1 className="font-InterSemiBold text-base mt-2 mb-4">
            Description:
          </h1>

          <p>{data.details}</p>
        </div>
        {/* <div className="w-full">
          <h1 className="font-InterSemiBold text-base mt-2 mb-4">Images:</h1>

          <div className="display-images">
            {[1, 2, 3, 4].map((file) => (
              <div key={file} className="photo bg-stroke" />
            ))}
          </div>
        </div> */}
      </div>
    </Modal>
  );
};

export { BillModal };
