import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/httpService";
import { AddCashbookI, UpdateCashbookI } from "types";

export const getAllCashbooks: any = createAsyncThunk(
  "cashbook/getAllCashbooks",
  async (
    {
      page = 1,
      limit = 10,
      type,
      search = null,
    }: {
      page: number;
      limit: number;
      type: string;
      search: string | number | null;
    },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.get(
        `/cashbook/list?page=${page}&limit=${limit}${
          type ? "&type=" + type : ""
        }${search ? "&search=" + search : ""}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue("Something went wrong at backend!");
    }
  },
);

export const addCashbook: any = createAsyncThunk(
  "cashbook/addCashbook",
  async (
    { data, type }: { data: AddCashbookI; type?: "customer" | "supplier" },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.post(`/cashbook/create`, {
        ...data,
        type,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const updateCashbook: any = createAsyncThunk(
  "cashbook/updateCashbook",
  async (
    {
      id,
      data,
      type,
    }: { id: string; data: UpdateCashbookI; type?: "customer" | "supplier" },

    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.patch(`/cashbook/${id}`, {
        ...data,
        type,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const deleteCashbook: any = createAsyncThunk(
  "cashbook/deleteCashbook",
  async (
    { id, type = "customer" }: { id: string; type?: "customer" | "supplier" },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.delete(`/cashbook/${id}`);
      return { ...response.data, deletedId: id };
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);
