import { UsergroupAddOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { Header } from "components/Common";
import { AddStaffModal, StaffBookTable } from "components/Customer/StaffBook";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import {
  addStaffbook,
  deleteStaffbook,
  updateStaffbook,
} from "store/features/staffbook/staffbook.service";
import { useAppDispatch } from "store/store";
import { AddStaffbookI, StaffBookI } from "types";
import { GTagEventCategory, GTagEventType, sendGTagEvent } from "utils/gtag";

const StaffBook: React.FC = () => {
  const dispatch = useAppDispatch();

  const { control, handleSubmit, reset } = useForm<AddStaffbookI>();

  const [type] = useState<string>("customer");
  const [modalType, setModalType] = useState<"add" | "edit">("add");
  const [addStaffModal, setAddStaffModal] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<StaffBookI | null>(null);

  const onSubmit = (data: AddStaffbookI) => {
    if (modalType === "add") {
      dispatch(addStaffbook({ data, type }))
        .unwrap()
        .then(() => {
          message.success("Staff record has been created successfully!");
          setAddStaffModal(false);
          reset();

          // sending gTag event here...
          sendGTagEvent(
            GTagEventType.ADD_STAFF,
            GTagEventCategory.STAFFBOOKS_CUSTOMERS
          );
        })
        .catch((errMessage: string) => message.error(errMessage));
    }

    if (modalType === "edit") {
      dispatch(updateStaffbook({ id: defaultValues?.id, data }))
        .unwrap()
        .then(() => {
          message.success("Staff record has been created successfully!");
          setAddStaffModal(false);
          reset();

          // sending gTag event here...
          sendGTagEvent(
            GTagEventType.UPDATE_STAFF,
            GTagEventCategory.STAFFBOOKS_CUSTOMERS
          );
        })
        .catch((errMessage: string) => message.error(errMessage));
    }
  };

  const createBillHandler = () => handleSubmit(onSubmit)();

  return (
    <div className="page-padding">
      <Header heading="Staff Book">
        <Button
          type="primary"
          size="large"
          icon={<UsergroupAddOutlined />}
          onClick={() => {
            setModalType("add");
            setAddStaffModal(true);
          }}
        >
          Add Staff
        </Button>
      </Header>
      <StaffBookTable
        onActionPress={(type, data) => {
          setDefaultValues(data);

          if (type.key === "edit") {
            setModalType("edit");
            setAddStaffModal(true);
          }

          if (type.key === "delete") {
            dispatch(deleteStaffbook({ id: data.id }));
            message.success("Staff record has been deleted successfully!");

            // sending gTag event here...
            sendGTagEvent(
              GTagEventType.DELETE_STAFF,
              GTagEventCategory.STAFFBOOKS_CUSTOMERS
            );
          }
        }}
      />
      <AddStaffModal
        type={modalType}
        reset={reset}
        control={control}
        open={addStaffModal}
        onCancel={() => setAddStaffModal(false)}
        onClickSave={createBillHandler}
        defaultValues={defaultValues}
      />
    </div>
  );
};

export default StaffBook;
