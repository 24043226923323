import { createSlice } from "@reduxjs/toolkit";
import {
  getAllCashbooks,
  addCashbook,
  deleteCashbook,
  updateCashbook,
} from "./cashbook.service";
import { CashbookI } from "types";

export interface AllCashbooksI {
  data: CashbookI[];
  page: number;
  limit: number;
  hasNext: boolean;
  totalPages: number;
}

export interface CashbookStoreI {
  error: string | null;
  loading: boolean;
  allCashBooks: AllCashbooksI | null;
}

const initialState: CashbookStoreI = {
  loading: false,
  error: null,
  allCashBooks: {
    data: [],
    page: 1,
    limit: 10,
    hasNext: false,
    totalPages: 1,
  },
};

export const cashbookSlice = createSlice({
  name: "cashbook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get All Cashbooks
      .addCase(getAllCashbooks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCashbooks.fulfilled, (state, action) => {
        state.loading = false;
        state.allCashBooks = action.payload.result;
      })
      .addCase(getAllCashbooks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add New Cashbook Record
      .addCase(addCashbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(addCashbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allCashBooks = {
          ...state.allCashBooks,
          data: [
            action.payload.result.cashbook,
            ...(state.allCashBooks?.data as CashbookI[]),
          ],
        } as AllCashbooksI;
      })
      .addCase(addCashbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Customer
      .addCase(updateCashbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateCashbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allCashBooks = {
          ...state.allCashBooks,
          data: state.allCashBooks?.data.map((cb) =>
            cb.id === action.payload.result.customer.id
              ? action.payload.result.customer
              : cb,
          ) as CashbookI[],
        } as AllCashbooksI;
      })
      .addCase(updateCashbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Cashbook
      .addCase(deleteCashbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteCashbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allCashBooks = {
          ...state.allCashBooks,
          data: state.allCashBooks?.data.filter(
            (cashbook) => cashbook.id !== action.payload.deletedId,
          ) as CashbookI[],
        } as AllCashbooksI;
      })
      .addCase(deleteCashbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = cashbookSlice.actions;

export default cashbookSlice.reducer;
