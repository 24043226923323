import { useEffect, useState } from "react";
import { WidgetContainer } from "../../Layouts/WidgetContainer/WidgetContainer";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { formatLargeNumber } from "utils/number";

const initialData = [
  {
    month: "January",
    revenue: 0,
  },
  {
    month: "February",
    revenue: 0,
  },
  {
    month: "March",
    revenue: 0,
  },
  {
    month: "April",
    revenue: 0,
  },
  {
    month: "May",
    revenue: 0,
  },
  {
    month: "June",
    revenue: 0,
  },
  {
    month: "July",
    revenue: 0,
  },
  {
    month: "August",
    revenue: 0,
  },
  {
    month: "September",
    revenue: 0,
  },
  {
    month: "October",
    revenue: 0,
  },
  {
    month: "November",
    revenue: 0,
  },
  {
    month: "December",
    revenue: 0,
  },
];

const MonthlyRevenueWidget = ({
  label,
  data,
}: {
  label: string;
  data: any;
}) => {
  const [dataset, setDataset] = useState([]);

  const revenueFormatter = (value: number) =>
    `Rs. ${formatLargeNumber(Math.round(value))}`;

  useEffect(() => {
    if (data && data.length) {
      setDataset(data);
    } else {
      setDataset(initialData as any);
    }
  }, [data]);

  return (
    <WidgetContainer title={label} headerRight={null}>
      <ResponsiveContainer width="100%" height={"85%"}>
        <BarChart data={dataset} margin={{left: 25, right: 25}}>
          <CartesianGrid
            vertical={false}
            stroke="grey"
            strokeWidth={0.5}
            strokeOpacity={0.2}
          />
          <XAxis dataKey="month" />
          <YAxis
            dataKey="revenue"
            stroke="grey"
            tickFormatter={revenueFormatter}
          />
          <Tooltip
            labelFormatter={(label) => (
              <p className="text-base font-semibold">{label}</p>
            )}
            formatter={(value) => `Rs. ${value}`}
          />
          <Bar name="Revenue" dataKey="revenue" fill="#0C8CE9" barSize={30} />
        </BarChart>
      </ResponsiveContainer>
    </WidgetContainer>
  );
};

export { MonthlyRevenueWidget };
