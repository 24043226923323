import BellIcon from "assets/icons/bell.svg";
import ChevronDownIcon from "assets/icons/chevron-down.svg";
import CommandSlashIcon from "assets/icons/command-slash.svg";
import SearchIcon from 'assets/icons/search.svg'
import MagnifierIcon from 'assets/icons/magnifier.svg'


export const icons = {
  bell: BellIcon,
  chevronDown: ChevronDownIcon,
  commandSlash: CommandSlashIcon,
  search: SearchIcon,
  magnifier: MagnifierIcon
};
