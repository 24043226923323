import * as yup from "yup";

export const createStockbookSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  unit: yup.string().required(),
  quantity: yup.number().min(1, "Min quantity should be atleast 1"),
  sellingPrice: yup.number().min(1, "Selling price should be > 0"),
  purchasePrice: yup.number().min(1, "Selling price should be > 0"),
  type: yup.string(),
});
