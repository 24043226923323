import { Button, Col, Modal, Row } from "antd";
import { CustomInput, CustomSelect } from "components/Elements";
import { useForm, Controller } from "react-hook-form";

type AddNewItemModalPropTypes = {
  open: boolean;
  onCancel: () => void;
  onClickSave?: () => void;
};

const UnitOptions = [
  {
    label: "Kg",
    value: "kg",
  },
  {
    label: "Pounds",
    value: "pound",
  },
];

const AddNewItemModal = ({
  open,
  onCancel,
  onClickSave,
}: AddNewItemModalPropTypes) => {
  const { register, reset, control, setValue, getValues } = useForm();

  const submitHandler = () => {
    const formValues = getValues();
  }

  return (
    <Modal
      title="Add New Item"
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={668}
      footer={[
        <Button
          size="large"
          type="primary"
          style={{ marginTop: "1rem" }}
          htmlType="submit"
          onClick={submitHandler}
        >
          Save
        </Button>,
      ]}
    >
      <div className="modal">
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }: any) => (
                <CustomInput
                  label="Item Name"
                  placeholder="item name"
                  {...field}
                />
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="unit"
              control={control}
              render={({ field }: any) => (
                <CustomSelect
                  label="Unit"
                  placeholder="kg"
                  options={UnitOptions}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="sellingRate"
              control={control}
              render={({ field }: any) => (
                <CustomInput
                  label="Rate (Sale)"
                  placeholder="rate"
                  {...field}
                />
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="purchaseRate"
              control={control}
              render={({ field }: any) => (
                <CustomInput
                  label="Rate (Purchase)"
                  placeholder="rate"
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export { AddNewItemModal };
