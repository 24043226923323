import { createSlice } from "@reduxjs/toolkit";
import {
  addBillbook,
  deleteBillbook,
  getAllBillbooks,
} from "./billbook.service";
import { BillBookI } from "types";

export interface AllBillbooksI {
  data: BillBookI[];
  page: number;
  limit: number;
  hasNext: boolean;
  totalPages: number;
}

export interface BillbookStoreI {
  error: string | null;
  loading: boolean;
  allBillBooks: AllBillbooksI | null;
}

const initialState: BillbookStoreI = {
  loading: false,
  error: null,
  allBillBooks: {
    data: [],
    page: 1,
    limit: 10,
    hasNext: false,
    totalPages: 1,
  },
};

export const cashbookSlice = createSlice({
  name: "stockbook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get All Cashbooks
      .addCase(getAllBillbooks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllBillbooks.fulfilled, (state, action) => {
        state.loading = false;
        state.allBillBooks = action.payload.result;
      })
      .addCase(getAllBillbooks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add New Billbook Record
      .addCase(addBillbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(addBillbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allBillBooks = {
          ...state.allBillBooks,
          data: [
            action.payload.result.billbook,
            ...(state.allBillBooks?.data as BillBookI[]),
          ],
        } as AllBillbooksI;
      })
      .addCase(addBillbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Cashbook
      .addCase(deleteBillbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteBillbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allBillBooks = {
          ...state.allBillBooks,
          data: state.allBillBooks?.data.filter(
            (billbook) => billbook.id !== action.payload.deletedId
          ) as BillBookI[],
        } as AllBillbooksI;
      })
      .addCase(deleteBillbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = cashbookSlice.actions;

export default cashbookSlice.reducer;
