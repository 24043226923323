import React from "react";
import { useNavigate } from "react-router-dom";

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full absolute top-1/2 transform -translate-y-1/2 text-center text-gray-700">
      <h2>Oops! Page not found.</h2>
      <h1 className="text-primary text-9xl font-InterBold tracking-wide">
        404
      </h1>
      <p>We can't find the page you're looking for.</p>
      <button
        className="mt-8 bg-primary text-white px-6 py-3 rounded-full"
        onClick={() => navigate("/dashboard")}
      >
        Go back home
      </button>
    </div>
  );
};

export default NotFoundPage;
