import { RefObject } from "react";
import html2canvas from "html2canvas";

export const downloadDivAsImage = async (ref: RefObject<any>, filename: string) => {
  const divToDownload = ref.current;

  if (!divToDownload) {
    console.error("Couldn't find the div to download.");
    return;
  }

  // Use html2canvas to capture the content of the div
  const canvas = await html2canvas(divToDownload);

  // Convert canvas to data URL
  const dataURL = canvas.toDataURL("image/png");

  // Create a downloadable link
  const downloadLink = document.createElement("a");
  downloadLink.href = dataURL;
  downloadLink.download = `${filename}.png`;

  // Append the link to the document and trigger the click event
  document.body.appendChild(downloadLink);
  downloadLink.click();

  // Remove the link from the document
  document.body.removeChild(downloadLink);
};
