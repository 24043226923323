import { useOutsideClick } from "hooks";
import { useRef, useState } from "react";

const CountryPicker = () => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    countryCode: "pk",
    name: "Pakistan",
    flag: "🇵🇰",
  });
  const [options] = useState([
    { countryCode: "pk", name: "Pakistan", flag: "🇵🇰" },
    { countryCode: "us", name: "United States", flag: "🇺🇸" },
  ]);

  const handleLanguageChange = async (countryCode: any) => {
    setSelectedCountry(countryCode);
    setIsOpen(false);
  };

  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  return (
    <>
      <div className="flex items-center z-40 select-none" ref={ref}>
        <div className="relative inline-block text-left">
          <div>
            <button
              onClick={() => setIsOpen(!isOpen)}
              type="button"
              className="h-8 inline-flex items-center justify-center gap-0 w-full px-4 py-2 text-sm font-medium focus:outline-none"
              aria-haspopup="true"
              aria-expanded={isOpen}
            >
              <p className="text-base">{selectedCountry.flag}</p>
              <svg
                className="-mr-1 ml-2 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="grey"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L10 12.586l3.293-3.293a1 1 0 011.414 1.414l-4 4z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          {isOpen && (
            <div
              className="origin-top-right absolute right-0 mt-2 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
              role="menu"
            >
              <div className="flex flex-col items-start gap-2 py-2">
                {options.map((country, index) => (
                  <button
                    key={country.countryCode}
                    onClick={() => handleLanguageChange(country)}
                    className="w-full rounded-sm px-2 py-1 flex items-center gap-3 hover:bg-stroke"
                  >
                    <p>{country.flag}</p>
                    <span className="truncate text-sm">{country.name}</span>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export { CountryPicker };
