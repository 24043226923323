import { createSlice } from "@reduxjs/toolkit";
import { listAnalytics } from "./analytics.service";

export interface RecentStockItemI {
  quantity: number;
  name: string;
  id: string;
}

export interface RevenueReportItemI {
  revenue: number;
  date: string;
}

export interface MonthRevenueReportItemI {
  month: string;
  revenue: number;
}

export interface AnalyticsDataI {
  totalCustomers: {
    value: number;
    change: {
      isPositive: boolean;
      value: number;
    } | null;
  };
  newCustomers: {
    value: number;
    change: {
      isPositive: boolean;
      value: number;
    } | null;
  };
  totalSuppliers: {
    value: number;
    change: {
      isPositive: boolean;
      value: number;
    } | null;
  };
  newSuppliers: {
    value: number;
    change: {
      isPositive: boolean;
      value: number;
    } | null;
  };
  recentStockUpdates: RecentStockItemI[];
  weeklyRevenueReport: {
    currYearData: RevenueReportItemI[];
    prevYearData: RevenueReportItemI[];
  };
  monthlyRevenueReports: MonthRevenueReportItemI[];
}

export interface ListAnalyticsI {
  loading: boolean;
  data: AnalyticsDataI;
  error: string | null;
}

const initialState: ListAnalyticsI = {
  loading: false,
  data: {
    totalCustomers: {
      value: 0,
      change: null,
    },
    newCustomers: {
      value: 0,
      change: null,
    },
    totalSuppliers: {
      value: 0,
      change: null,
    },
    newSuppliers: {
      value: 0,
      change: null,
    },
    recentStockUpdates: [],
    weeklyRevenueReport: {
      currYearData: [],
      prevYearData: [],
    },
    monthlyRevenueReports: [],
  },
  error: null,
};

export const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // List Analytics
      .addCase(listAnalytics.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(listAnalytics.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.data = {
          ...state.data,
          ...action.payload.result,
        };
      })
      .addCase(listAnalytics.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default analyticsSlice.reducer;
