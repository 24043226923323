import { Button, Modal, AutoComplete, Table } from "antd";
import { useEffect, useState } from "react";
import { useAppDispatch } from "store/store";
import { getAllStockbooks } from "store/features/stockbook/stockbook.service";
import { StockbookI } from "types";
import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

type AddItemsModalPropTypes = {
  open: boolean;
  onCancel: () => void;
  onClickSave?: (data: SelectedStockbookI[]) => void;
  onClickAddNewItem?: () => void;
  selectedItems?: SelectedStockbookI[];
};

export interface SelectedStockbookI extends StockbookI {
  mQuantity: number;
}

const AddItemsModal = ({
  open,
  onCancel,
  onClickSave,
  onClickAddNewItem,
  selectedItems: sItems,
}: AddItemsModalPropTypes) => {

  const dispatch = useAppDispatch();
  const [options, setOptions] = useState<StockbookI[]>([]);
  const [selectedItems, setSelectedItems] = useState<SelectedStockbookI[]>([]);

  const onSelect = (value: string) => {
    // Find the selected item object from the options
    const selected = options.find((item: StockbookI) => item.name === value);

    if (selected) {
      // Check if the item is already in the selected items array
      const itemIndex = selectedItems.findIndex(
        (item) => item.name === selected.name,
      );

      if (itemIndex !== -1) {
        // If the item is already in the array, increment its quantity
        setSelectedItems((prev) => {
          const updatedItems = [...prev];
          updatedItems[itemIndex].mQuantity += 1;
          return updatedItems;
        });
      } else {
        // If the item is not in the array, add it with a quantity of 1
        setSelectedItems((prev) => {
          return [...prev, { ...selected, mQuantity: 1 }];
        });
      }
    }
  };

  const handleSearch = (value: string) => {
    if (!value) {
      setOptions([]);
    } else {
      dispatch(getAllStockbooks({ type: "supplier", page: 1, limit: 10 }))
        .unwrap()
        .then((response: any) => {
          const res = response.result?.data.filter((item: StockbookI) =>
            item.name.toLowerCase().includes(value.toLowerCase()),
          );
          setOptions(res);
        });
    }
  };

  const incDecHandler = (id: string, action: string) => {
    // Find the item in the selectedItems array
    const index = selectedItems.findIndex((el) => el.id === id);

    if (index !== -1) {
      // Make a copy of the selectedItems array
      const updatedItems = [...selectedItems];

      // Modify the quantity of the found item
      if (action === "inc") {
        updatedItems[index].mQuantity += 1;
      } else if (action === "dec" && updatedItems[index].mQuantity > 0) {
        updatedItems[index].mQuantity -= 1;
      }

      // Update the state with the updated array
      setSelectedItems(updatedItems);
    }
  };

  const removeItemFromSelectedList = (id: string) => {
    let newArr = selectedItems.filter((el) => el.id !== id);
    setSelectedItems(newArr);
  };

  const columns: ColumnsType<SelectedStockbookI> = [
    {
      title: "Item",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "sellingPrice",
      key: "sellingPrice",
    },
    {
      title: "Quantity",
      key: "action",
      render: ({ mQuantity, id, quantity }: SelectedStockbookI) => (
        <div className="flex justify-center">
          <div className="w-[100px] flex justify-between items-center gap-4">
            <div
              className="cursor-pointer"
              onClick={() => {
                if (mQuantity > 1) {
                  incDecHandler(id, "dec");
                }
              }}
            >
              <MinusCircleOutlined />
            </div>
            {mQuantity}
            <div
              className="cursor-pointer"
              onClick={() => {
                if (mQuantity < quantity) {
                  incDecHandler(id, "inc");
                }
              }}
            >
              <PlusCircleOutlined />
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: ({ id }: SelectedStockbookI) => (
        <div
          className="cursor-pointer"
          onClick={() => removeItemFromSelectedList(id)}
        >
          <DeleteOutlined />
        </div>
      ),
    },
  ];

  const onSubmitHandler = () => {
    if (onClickSave && selectedItems) {
      onClickSave(selectedItems);
    }
  };

  useEffect(() => {
    if (sItems?.length) {
      setSelectedItems(sItems);
    }

    return () => {
      setSelectedItems([]);
      setOptions([]);
    };
  }, [sItems]);

  return (
    <Modal
      title="Add items"
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={668}
      footer={[
        <Button size="large" type="primary" onClick={onSubmitHandler}>
          Save
        </Button>,
      ]}
    >
      <div className="add-item-modal mt-10">
        <AutoComplete
          options={options.map((item: StockbookI) => ({
            label: item.name,
            value: item.name,
          }))}
          onSearch={handleSearch}
          onSelect={onSelect}
          placeholder="Search item"
          className="w-full"
          size="large"
          allowClear
        />
        <div className="w-full">
          {selectedItems.length > 0 && (
            <div className="mTable">
              <Table
                dataSource={selectedItems}
                columns={columns}
                className="select-none"
                pagination={false}
                rowKey={(item) => item.id}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export { AddItemsModal };
