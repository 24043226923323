import { InputHTMLAttributes } from "react";
import { Input } from "antd";

interface CustomInputI extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
}

const CustomInput = ({
  label,
  type,
  placeholder,
  disabled,
  value,
  ...rest
}: CustomInputI) => {
  return (
    <div className="custom-input">
      <label className="label">{label}</label>
      <Input
        type={type ?? "text"}
        placeholder={placeholder}
        className="input-field"
        disabled={disabled}
        value={value}
        {...rest}
        size={"large"}
      />
    </div>
  );
};

export { CustomInput };
