import * as yup from "yup";

export const createCashbookSchema = yup.object().shape({
  // userId: yup.string().required("Select a Customer"),
  date: yup.string().required("Date is required"),
  particulars: yup.string().required("Particulars are required"),
  referenceNo: yup.string().required("Reference no. is required"),
  cashIn: yup.number().optional(),
  cashOut: yup.number().optional(),
  totalAmount: yup.number().optional(),
  type: yup.string(),
});
