import React, { useState } from "react";
import { Button, Col, Modal, Radio, Row, Typography } from "antd";
import { CustomInput, TextArea } from "components/Elements";
import { RightOutlined, CloseOutlined } from "@ant-design/icons";
import { SelectedStockbookI } from "./AddItemsModal";
import { Control, Controller, FormState } from "react-hook-form";
import { AddBillbookI } from "types";
import { Select } from "components/Common";

type CreateBillModalPropTypes = {
  formState: FormState<AddBillbookI>;
  control: Control<any>;
  open: boolean;
  items: SelectedStockbookI[];
  setItems: any;
  onCancel: () => void;
  onClickItems?: () => void;
  onClickSave?: () => void;
};

const CreateBillModal = ({
  formState: { errors },
  control,
  open,
  items,
  setItems,
  onCancel,
  onClickItems,
  onClickSave,
}: CreateBillModalPropTypes) => {
  const [fileList, setFileList] = useState<string[]>([]);

  const onPhotosChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const images = [...fileList];
    if (files && files?.length > 0) {
      for (let i = 0; i < files?.length; i++) {
        images.push(URL.createObjectURL(files[i]));
      }
    }
    setFileList(images);
  };

  const removeImageHandler = (itemIndex: number) => {
    let newArr = fileList.filter((item, index) => index !== itemIndex);
    setFileList(newArr);
  };

  const removeItemHandler = (itemIndex: number) => {
    let newArr = items.filter((_, index) => index !== itemIndex);
    setItems(newArr);
  };

  return (
    <Modal
      title="Create Bill"
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={668}
      footer={[
        <Button
          size="large"
          type="primary"
          onClick={() => {
            setFileList([]);
            onClickSave && onClickSave();
          }}
        >
          Save
        </Button>,
      ]}
    >
      <div className="modal">
        <Row gutter={[28, 28]}>
          <Col xs={24}>
            <Controller
              name={"userId" as any}
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    label="Select Supplier*"
                    placeholder="Select a Supplier"
                    value={field.value as any}
                    onSelect={(selectedOpt: { label: string; value: string }) =>
                      field.onChange(selectedOpt)
                    }
                  />
                  {/* {errors.userId && (
                    <p className="fieldValidationError">
                      {errors.userId.message}
                    </p>
                  )} */}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col span={24}>
            <label className="custom-input">
              <label className="label">Items</label>
              <div className="items-select" onClick={onClickItems}>
                <Typography.Paragraph className="placeholder">
                  Select Items
                </Typography.Paragraph>
                <RightOutlined className="icon" />
              </div>
            </label>
          </Col>
        </Row>
        {items?.length !== 0 && (
          <Row gutter={[28, 28]}>
            <Col span={24}>
              <div className="flex flex-wrap gap-2">
                {items.map((el, index) => (
                  <div
                    key={index}
                    className="flex items-center gap-2 border px-2 py-1 rounded-lg"
                  >
                    <p>{`${el.name}  (${el.mQuantity})`}</p>
                    <div
                      className="cursor-pointer"
                      onClick={() => removeItemHandler(index)}
                    >
                      <CloseOutlined className="w-[16px] h-[16px] p-[3px] bg-primary text-white rounded-full" />
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        )}

        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="amount"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    {...field}
                    label="Amount*"
                    type="number"
                    placeholder="amount"
                    value={String(field.value)}
                    onChange={(e) => {
                      const numericValue = parseInt(e.target.value);
                      field.onChange(isNaN(numericValue) ? 0 : numericValue);
                    }}
                  />
                  {errors.amount && (
                    <p className="fieldValidationError">
                      {errors.amount.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>

          <Col xs={24} md={12}>
            <Controller
              name="billType"
              control={control}
              render={({ field }) => (
                <>
                  <div className="mb-4">
                    <label className="text-base">Cash Type</label>
                  </div>
                  <Radio.Group {...field}>
                    <Radio value="cash">Cash</Radio>
                    <Radio value="credit">Credit</Radio>
                  </Radio.Group>
                  {errors.billType && (
                    <p className="fieldValidationError !mt-5">
                      {errors.billType.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col span={24}>
            <Controller
              name="details"
              control={control}
              render={({ field }) => (
                <>
                  <TextArea
                    label="Details"
                    placeholder="Transaction Details"
                    {...field}
                  />
                  {errors.details && (
                    <p className="fieldValidationError">
                      {errors.details.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <label className="custom-input">
              <label className="label">Photos</label>
              <input
                type="file"
                placeholder="Upload from device"
                className="input-field input-file"
                accept="image/png, image/jpeg, image/jpg"
                multiple
                onChange={onPhotosChange}
              />
              <div className="input-file-div">
                <Typography.Paragraph className="placeholder">
                  Select Photos
                </Typography.Paragraph>
              </div>
            </label>
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    type="date"
                    label="Date"
                    placeholder="dd/mm/yyyy"
                    {...field}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {errors.date && (
                    <p className="fieldValidationError">
                      {errors.date.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        {fileList?.length > 0 && (
          <Row gutter={[28, 28]}>
            <Col xs={24} md={12}>
              <div className="display-images">
                {fileList.map((file, index) => (
                  <div className="relative" key={index}>
                    <img src={file} key={file} alt="" className="photo" />
                    <div
                      className="absolute -top-1 -right-1 cursor-pointer"
                      onClick={() => removeImageHandler(index)}
                    >
                      <CloseOutlined className="w-[16px] h-[16px] p-[3px] bg-primary text-white rounded-full" />
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
};

export { CreateBillModal };
