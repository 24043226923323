import { Header } from "components/Common";
import React from "react";

const Notification: React.FC = () => {
  return (
    <div className="page-padding">
      <Header heading="All Notifications" />

      <div className="mt-2">
        <p>No notifications yet!</p>
      </div>
    </div>
  );
};

export default Notification;
