import React from "react";
import { Button, Col, Modal, Row } from "antd";
import { CustomInput } from "components/Elements";

type StockInModalPropTypes = {
  open: boolean;
  onCancel: () => void;
};

const StockInModal = ({ open, onCancel }: StockInModalPropTypes) => {
  return (
    <Modal
      title="Stock In"
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={668}
      footer={[
        <Button
          size="large"
          type="primary"
          style={{ marginTop: "1rem" }}
          onClick={onCancel}
        >
          Save
        </Button>,
      ]}
    >
      <div className="modal">
        <Row gutter={[28, 28]}>
          <Col span={24}>
            <CustomInput
              type="number"
              label="Quantity"
              placeholder="Quantity"
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <CustomInput label="Rate" placeholder="rate" />
          </Col>
          <Col xs={24} md={12}>
            <CustomInput type="number" label="Amount" placeholder="amount" />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export { StockInModal };
