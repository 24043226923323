import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Col, Modal, Row, message } from "antd";
import { Select } from "components/Common";
import { CustomInput, CustomSelect } from "components/Elements";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  addStockbook,
  updateStockbook,
} from "store/features/stockbook/stockbook.service";
import { useAppDispatch } from "store/store";
import { AddStockbookI } from "types";
import { GTagEventCategory, GTagEventType, sendGTagEvent } from "utils/gtag";
import { createStockbookSchema } from "validations";

const defaultStockbookData: AddStockbookI = {
  userId: null,
  name: "",
  purchasePrice: 0,
  sellingPrice: 0,
  quantity: 0,
  unit: "kilogram",
};

type AddStockModalPropTypes = {
  open: boolean;
  type: "edit" | "add";
  defaultValues?: (AddStockbookI & { id: string }) | null;
  onCancel: () => void;
};

const AddStockModal = ({
  open,
  type,
  defaultValues,
  onCancel,
}: AddStockModalPropTypes) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createStockbookSchema),
  });

  const onSubmit = (data: AddStockbookI) => {
    data.userId = data?.userId?.value ?? null;

    if (type === "add") {
      dispatch(addStockbook({ data }))
        .unwrap()
        .then(() => {
          // sending gTag event here...
          sendGTagEvent(
            GTagEventType.ADD_STOCK,
            GTagEventCategory.STOCKBOOKS_CUSTOMERS
          );

          reset();
          message.success("Stock record has been created successfully!");
          onCancel();
        })
        .catch((errMessage: string) => {
          message.error(errMessage);
        });
    }

    if (type === "edit") {
      dispatch(updateStockbook({ id: defaultValues?.id, data }))
        .unwrap()
        .then(() => {
          // sending gTag event here...
          sendGTagEvent(
            GTagEventType.UPDATE_STOCK,
            GTagEventCategory.STOCKBOOKS_CUSTOMERS
          );

          reset();
          message.success("Stock record has been updated successfully!");
          onCancel();
        })
        .catch((errMessage: string) => {
          message.error(errMessage);
        });
    }
  };

  // @ts-ignore
  const addStockHandler = () => handleSubmit(onSubmit)();

  useEffect(() => {
    if (type === "add") {
      reset(defaultStockbookData);
    } else if (type === "edit" && defaultValues) {
      let userIdData = defaultValues?.userId
        ? {
            label: defaultValues?.userId?.name,
            value: defaultValues?.userId?.id,
          }
        : null;

      reset({
        // @ts-ignore
        userId: userIdData,
        name: defaultValues.name,
        purchasePrice: defaultValues.purchasePrice,
        quantity: defaultValues.quantity,
        sellingPrice: defaultValues.sellingPrice,
        unit: defaultValues.unit,
      });
    }
  }, [type, defaultValues]);

  return (
    <Modal
      title={`${type === "add" ? "Add" : "Edit"} Stock`}
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={668}
      footer={[
        <Button
          size="large"
          type="primary"
          style={{ marginTop: "1rem" }}
          onClick={addStockHandler}
          htmlType="submit"
        >
          {type === "add" ? "Add Stock" : "Update"}
        </Button>,
      ]}
    >
      <div className="modal">
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name={"userId" as any}
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    label="Select Customer*"
                    placeholder="Select a Customer"
                    value={field.value as any}
                    onSelect={(selectedOpt: { label: string; value: string }) =>
                      field.onChange(selectedOpt)
                    }
                  />
                  {/* {errors.userId && (
                    <p className="fieldValidationError">
                      {errors.userId.message}
                    </p>
                  )} */}
                </>
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    label="Item Name"
                    placeholder="item name"
                    {...field}
                  />
                  {errors.name && (
                    <p className="fieldValidationError">
                      {errors.name.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="quantity"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    {...field}
                    type="number"
                    label="Quantity"
                    placeholder="Quantity"
                    value={String(field.value)}
                    onChange={(e) => {
                      const numericValue = parseInt(e.target.value);
                      field.onChange(isNaN(numericValue) ? 0 : numericValue);
                    }}
                  />
                  {errors.quantity && (
                    <p className="fieldValidationError">
                      {errors.quantity.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="unit"
              control={control}
              render={({ field }) => (
                <>
                  <CustomSelect
                    label="Unit"
                    placeholder="kg"
                    options={[
                      {
                        label: "Kg",
                        value: "kilogram",
                      },
                      {
                        label: "Pounds",
                        value: "pound",
                      },
                    ]}
                    {...field}
                  />
                  {errors.unit && (
                    <p className="fieldValidationError">
                      {errors.unit.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="sellingPrice"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    {...field}
                    type="number"
                    label="Rate (Sale)"
                    placeholder="rate"
                    value={String(field.value)}
                    onChange={(e) => {
                      const numericValue = parseInt(e.target.value);
                      field.onChange(isNaN(numericValue) ? 0 : numericValue);
                    }}
                  />
                  {errors.sellingPrice && (
                    <p className="fieldValidationError">
                      {errors.sellingPrice.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="purchasePrice"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    {...field}
                    type="number"
                    label="Rate (Purchase)"
                    placeholder="rate"
                    value={String(field.value)}
                    onChange={(e) => {
                      const numericValue = parseInt(e.target.value);
                      field.onChange(isNaN(numericValue) ? 0 : numericValue);
                    }}
                  />
                  {errors.purchasePrice && (
                    <p className="fieldValidationError">
                      {errors.purchasePrice.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export { AddStockModal };
