import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/httpService";
import { AddBillbookI } from "types";

export const getAllBillbooks: any = createAsyncThunk(
  "billbook/getAllBillbooks",
  async (
    {
      page = 1,
      limit = 10,
      type,
      search = null,
    }: {
      page: number;
      limit: number;
      type: string;
      search: string | number | null;
    },
    { rejectWithValue }: any
  ) => {
    try {
      const response = await axiosInstance.get(
        `/billbook/list?page=${page}&limit=${limit}${
          type ? "&type=" + type : ""
        }${search ? "&search=" + search : ""}`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue("Something went wrong at backend!");
    }
  }
);

export const addBillbook: any = createAsyncThunk(
  "billbook/addBillbook",
  async (
    { data, type }: { data: AddBillbookI; type: "customer" | "supplier" },
    { rejectWithValue }: any
  ) => {
    try {
      const response = await axiosInstance.post(`/billbook/create`, {
        ...data,
        type,
        photos: [],
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  }
);

export const deleteBillbook: any = createAsyncThunk(
  "billbook/deleteBillbook",
  async ({ id }: { id: string }, { rejectWithValue }: any) => {
    try {
      const response = await axiosInstance.delete(`/billbook/${id}`);
      return { ...response.data, deletedId: id };
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  }
);
