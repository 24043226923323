import React, { useState } from "react";
import {
  DownloadOutlined,
  FilterOutlined,
  SearchOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Dropdown, Input, MenuProps, Select } from "antd";
import { CSVLink } from "react-csv";
import { generateTimestampString } from "utils/text";
import { useReactToPrint } from "react-to-print";

interface TableHeaderI {
  data?: any;
  placeholder?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  handleFilterChange?: (value: string) => void;
  filterSelected?: string | null;
  actionBtns?: boolean;
  tableContainerRef?: any;
  filterOptions?: any;
  searchValue?: string;
}

const TableHeader = ({
  data,
  placeholder = "Search",
  onChange,
  handleFilterChange,
  filterSelected,
  searchValue,
  actionBtns = true,
  tableContainerRef,
  filterOptions,
}: TableHeaderI) => {
  const onPressPDFPrint = useReactToPrint({
    content: () => tableContainerRef && tableContainerRef.current,
  });

  const items: MenuProps["items"] = [
    {
      label: (
        <CSVLink
          data={data ? data : []}
          filename={`file_${generateTimestampString()}.csv`}
        >
          Download as CSV File
        </CSVLink>
      ),
      key: "0",
    },
    {
      label: "Download as PDF File",
      key: "1",
      onClick: onPressPDFPrint,
    },
  ];

  return (
    <div className="table-header gap-4">
      <div className="flex gap-2">
        {filterOptions && (
          <Select
            size="large"
            placeholder="Filter By"
            value={filterSelected}
            onChange={(value) => {
              if (handleFilterChange) {
                handleFilterChange(value);
              }
            }}
            className="flex w-[350px] max-w-[500px]"
            options={filterOptions}
          />
        )}
        {searchValue && (
          <Input
            placeholder={placeholder}
            prefix={<SearchOutlined />}
            value={searchValue}
            className="search-input"
            onChange={onChange}
          />
        )}
        {!searchValue && (
          <Input
            placeholder={placeholder}
            prefix={<SearchOutlined />}
            className="search-input"
            onChange={onChange}
          />
        )}
      </div>
      {actionBtns && (
        <Dropdown menu={{ items }} trigger={["click"]}>
          <div className="action-icons">
            {/* <FilterOutlined className="icon" /> */}
            {/* <ShareAltOutlined className="icon" /> */}
            <DownloadOutlined className="icon" />
          </div>
        </Dropdown>
      )}
    </div>
  );
};

export { TableHeader };
