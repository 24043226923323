import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Table } from "antd";
import { TableHeader } from "components/Common";
import { STAFF_BOOK_COLUMNS, STAFF_BOOK_ACTION_ITEMS as items } from "const";
import { RootState, useAppDispatch, useAppSelector } from "store/store";
import { getAllStaffbooks } from "store/features/staffbook/staffbook.service";
import { ColumnsType } from "antd/es/table";
import { StaffBookI } from "types";
import { ChevronDown } from "assets/icons";

interface StaffBookTableI {
  onActionPress?: (type: { key: string }, data?: any) => void;
}

const StaffBookTable = ({ onActionPress }: StaffBookTableI) => {
  const dispatch = useAppDispatch();
  const tableContainerRef = useRef(null);
  const [type] = useState<string>("customer");
  const { allStaffBooks } = useAppSelector(
    (state: RootState) => state.staffbook
  );

  const [activeRow, setActiveRow] = useState<StaffBookI | null>(null);

  useEffect(() => {
    dispatch(getAllStaffbooks({ type, page: 1, limit: 10 }));
  }, [dispatch]);

  const querySearchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    dispatch(
      getAllStaffbooks({
        type,
        page: 1,
        limit: 10,
        search: e.currentTarget.value,
      })
    );
  };

  const menuProps = {
    items,
    onClick: (type: { key: string }) => {
      onActionPress && onActionPress(type, activeRow);
    },
  };

  const TABLE_COLUMNS: ColumnsType<StaffBookI> = [
    {
      title: "Sr.",
      key: "serial",
      render: (text, record, index) => {
        const current = allStaffBooks?.page || 1;
        const pageSize = allStaffBooks?.limit || 10;
        const sr = (current - 1) * pageSize + index + 1;
        return sr;
      },
    },
    ...STAFF_BOOK_COLUMNS,
    {
      title: "Action",
      key: "action",
      render: (props) => (
        <Dropdown
          menu={menuProps as any}
          trigger={"click" as any}
          onOpenChange={(open: boolean) => {
            if (open) {
              setActiveRow(props);
            }
          }}
        >
          <Button className="action-btn">
            Actions
            <img src={ChevronDown} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="page-padding display-center-col">
      <div className="table-container">
        <TableHeader
          onChange={querySearchHandler}
          data={allStaffBooks?.data}
          tableContainerRef={tableContainerRef}
        />
        <div ref={tableContainerRef} className="overflow-y-auto">
          <Table
            columns={TABLE_COLUMNS}
            dataSource={allStaffBooks?.data}
            className="customer-table"
            rowKey={(item) => item.id}
            pagination={{
              pageSize: allStaffBooks?.limit,
              total: allStaffBooks?.totalPages,
              onChange: (page, pageSize) => {
                dispatch(getAllStaffbooks({ type, page, limit: pageSize }));
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { StaffBookTable };
