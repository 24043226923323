import { message } from "antd";
import {
  CashbookTable,
  CashbookHeader,
  AddCashbookModal,
} from "components/Supplier/CashBook";
import React, { useState } from "react";
import { deleteCashbook } from "store/features/cashbook/cashbook.service";
import { useAppDispatch } from "store/store";
import { CashbookI } from "types";
import { GTagEventCategory, GTagEventType, sendGTagEvent } from "utils/gtag";

const CashBook: React.FC = () => {
  const dispatch = useAppDispatch();
  const [modalType, setModalType] = useState<"add" | "edit">("add");
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<CashbookI | null>(null);

  const handleModalClose = () => {
    setOpenAddModal(false);
  };

  return (
    <div className="page-padding">
      <CashbookHeader
        setOpenCashInModal={() => {
          setModalType("add");
          setOpenAddModal(true);
        }}
        setOpenCashOutModal={() => {
          setModalType("add");
          setOpenAddModal(true);
        }}
      />
      <CashbookTable
        onActionPress={(type, data) => {
          setDefaultValues(data);

          if (type.key === "edit") {
            setModalType("edit");
            setOpenAddModal(true);
          }

          if (type.key === "delete") {
            dispatch(deleteCashbook({ id: data.id }));
            message.success("Customer has been deleted successfully!");

            // sending gTag event here...
            sendGTagEvent(
              GTagEventType.DELETE_CASH,
              GTagEventCategory.CASHBOOKS_SUPPLIERS
            );
          }
        }}
      />

      <AddCashbookModal
        open={openAddModal}
        type={modalType}
        onCancel={handleModalClose}
        defaultValues={defaultValues}
      />
      {/* <CashInModal open={openCashInModal} onCancel={handleCashInModalClose} />
      <CashOutModal
        open={openCashOutModal}
        onCancel={handleCashOutModalClose}
      /> */}
    </div>
  );
};

export default CashBook;
