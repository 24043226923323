export const capitalize = (input: string): string => {
  if (input.length === 0) {
    return input;
  }
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const generateTimestampString = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, "0");
  const day = String(now.getDate()).padStart(2, "0");
  const hours = String(now.getHours()).padStart(2, "0");
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

  const timestamp = `${year}-${month}-${day}_${hours}-${minutes}-${seconds}-${milliseconds}`;
  return timestamp;
};

export const formatCNIC = (value: string) => {
  const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters

  // Format the CNIC number with dashes
  let formattedCnic = "";
  if (numericValue.length <= 5) {
    formattedCnic = numericValue;
  } else if (numericValue.length <= 12) {
    formattedCnic = `${numericValue.slice(0, 5)}-${numericValue.slice(5, 12)}`;
  } else {
    formattedCnic = `${numericValue.slice(0, 5)}-${numericValue.slice(
      5,
      12
    )}-${numericValue.slice(12, 13)}`;
  }

  return formattedCnic
};
