import * as yup from "yup";

export const createCustomerSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  phone: yup
    .string()
    .matches(/^\+923[0-9]{9}$/, "Invalid phone number")
    .required("Phone is required"),
  address: yup.string().required("Location is required"),
  cnic: yup.string(),
  amountGiven: yup.number(),
  amountReceived: yup.number(),
  email: yup.string().email("Enter a valid email").optional(),
  details: yup.string(),
});
