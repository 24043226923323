import axios, { AxiosError, AxiosInstance } from "axios";

const httpService: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/v1`,
  // withCredentials: true, // Enable CORS credentials
  // headers: {
  //   "Content-Type": "application/json", // Set the appropriate content type
  // },
});

// Add an interceptor to include the Bearer token in the request headers
httpService.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("USER_ACCESS_TOKEN");

    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

httpService.interceptors.response.use(
  (response) => response,
  (error) => {
    const axiosError = error as AxiosError;
    return Promise.reject({
      status: axiosError.response?.status,
      data: axiosError.response?.data || axiosError.message,
    });
  },
);

export default httpService;
