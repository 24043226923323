import { Button, Col, Modal, Row, message } from "antd";
import { CustomInput } from "components/Elements";
import { Controller, useForm } from "react-hook-form";
import { useAppDispatch } from "store/store";
import { AddCashbookI } from "types";
import {
  addCashbook,
  updateCashbook,
} from "store/features/cashbook/cashbook.service";
import { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCashbookSchema } from "validations";
import { GTagEventCategory, GTagEventType, sendGTagEvent } from "utils/gtag";
import { Select } from "components/Common";

const defaultCashbookData: AddCashbookI = {
  userId: null,
  cashIn: 0,
  cashOut: 0,
  date: "",
  particulars: "",
  referenceNo: "",
  totalAmount: 0,
  type: "supplier",
};

type AddCashbookModalPropTypes = {
  open: boolean;
  type: "add" | "edit";
  defaultValues?: (AddCashbookI & { id: string }) | null;
  onCancel: () => void;
};

const AddCashbookModal = ({
  open,
  type,
  defaultValues,
  onCancel,
}: AddCashbookModalPropTypes) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createCashbookSchema),
  });

  const onSubmit = (data: AddCashbookI) => {
    data.userId = data?.userId?.value ?? null;

    if (type === "add") {
      dispatch(addCashbook({ data, type: "supplier" }))
        .unwrap()
        .then(() => {
          // sending gTag event here...
          sendGTagEvent(
            GTagEventType.ADD_CASH,
            GTagEventCategory.CASHBOOKS_SUPPLIERS
          );

          reset();
          message.success("Cashbbook record has been created successfully!");
          onCancel();
        })
        .catch((errMessage: string) => {
          message.error(errMessage);
        });
    }

    if (type === "edit") {
      dispatch(
        updateCashbook({ id: defaultValues?.id, data, type: "supplier" })
      )
        .unwrap()
        .then(() => {
          // sending gTag event here...
          sendGTagEvent(
            GTagEventType.UPDATE_CASH,
            GTagEventCategory.CASHBOOKS_SUPPLIERS
          );

          reset();
          message.success("Cashbbook record has been updated successfully!");
          onCancel();
        })
        .catch((errMessage: string) => {
          message.error(errMessage);
        });
    }
  };

  // @ts-ignore
  const submitForm = () => handleSubmit(onSubmit)();

  useEffect(() => {
    if (type === "add") {
      reset(defaultCashbookData);
    } else if (type === "edit" && defaultValues) {
      let userIdData = defaultValues?.userId
        ? {
            label: defaultValues?.userId?.name,
            value: defaultValues?.userId?.id,
          }
        : null;

      reset({
        // @ts-ignore
        userId: userIdData,
        cashIn: defaultValues.cashIn,
        cashOut: defaultValues.cashOut,
        date: defaultValues.date,
        particulars: defaultValues.particulars,
        referenceNo: defaultValues.referenceNo,
        totalAmount: defaultValues.totalAmount,
        type: "supplier",
      });
    }
  }, [type, defaultValues]);

  return (
    <Modal
      title={`${type === "add" ? "Add" : "Edit"} Cashbook`}
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={668}
      footer={[
        <Button
          size="large"
          type="primary"
          style={{ marginTop: "1rem" }}
          onClick={submitForm}
          htmlType="submit"
        >
          Save
        </Button>,
      ]}
    >
      <div className="modal">
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name={"userId" as any}
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    label="Select Supplier*"
                    placeholder="Select a Supplier"
                    value={field.value as any}
                    type="supplier"
                    onSelect={(selectedOpt: { label: string; value: string }) =>
                      field.onChange(selectedOpt)
                    }
                  />
                  {/* {errors.userId && (
                    <p className="fieldValidationError">
                      {errors.userId.message}
                    </p>
                  )} */}
                </>
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="particulars"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    label="Particulars"
                    placeholder="Transaction details"
                    {...field}
                  />
                  {errors.particulars && (
                    <p className="fieldValidationError">
                      {errors.particulars.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="cashIn"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    {...field}
                    type="number"
                    label="Cash In"
                    placeholder="Amount"
                    value={String(field.value)}
                    onChange={(e) => {
                      const numericValue = parseInt(e.target.value);
                      field.onChange(isNaN(numericValue) ? 0 : numericValue);
                    }}
                  />
                  {errors.cashIn && (
                    <p className="fieldValidationError">
                      {errors.cashIn.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="cashOut"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    {...field}
                    type="number"
                    label="Cash Out"
                    placeholder="Amount"
                    value={String(field.value)}
                    onChange={(e) => {
                      const numericValue = parseInt(e.target.value);
                      field.onChange(isNaN(numericValue) ? 0 : numericValue);
                    }}
                  />
                  {errors.cashOut && (
                    <p className="fieldValidationError">
                      {errors.cashOut.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="referenceNo"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    label="Reference Number*"
                    placeholder="Bill no"
                    {...field}
                  />
                  {errors.referenceNo && (
                    <p className="fieldValidationError">
                      {errors.referenceNo.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="date"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    type="date"
                    label="Date"
                    placeholder="dd/mm/yyyy"
                    {...field}
                    onKeyDown={(e) => e.preventDefault()}
                    onChange={(e) => field.onChange(e.target.value)}
                  />
                  {errors.date && (
                    <p className="fieldValidationError">
                      {errors.date.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export { AddCashbookModal };
