import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import authReducer from "./features/auth/auth.reducer";
import customerReducer from "./features/customer/customer.reducer";
import cashbookReducer from "./features/cashbook/cashbook.reducer";
import stockbookReducer from "./features/stockbook/stockbook.reducer";
import billbookReducer from "./features/billbook/billbook.reducer";
import staffbookReducer from "./features/staffbook/staffbook.reducer";
import analyticsReducer from "./features/analytics/analytics.reducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["customer", "cashbook", "stockbook", "billbook", "staffbook", "analytics"],
};

const rootReducer = combineReducers({
  user: authReducer,
  customer: customerReducer,
  cashbook: cashbookReducer,
  stockbook: stockbookReducer,
  billbook: billbookReducer,
  staffbook: staffbookReducer,
  analytics: analyticsReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
