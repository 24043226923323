import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { MenuProps } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  BillI,
  CashbookI,
  CustomerI,
  StaffBookI,
  StockBookDetailsI,
  StockbookI,
  SupplierI,
} from "types";
import { capitalize } from "utils/text";

// Customer table action button menu items
export const CUSTOMER_ACTION_ITEMS: MenuProps["items"] = [
  {
    label: "Edit",
    key: "edit",
    icon: <EditOutlined className="icon" />,
  },
  {
    label: "Delete",
    key: "delete",
    icon: <DeleteOutlined className="icon" />,
  },
  // {
  //   label: "Switch to Supplier",
  //   key: "3",
  //   icon: <img src={Switch} className="icon" />,
  // },
];

export const COLUMNS: ColumnsType<CustomerI> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Amount Given",
    dataIndex: "amountGiven",
    key: "amountGiven",
  },
  {
    title: "Amount Received",
    dataIndex: "amountReceived",
    key: "amountReceived",
  },
  {
    title: "CNIC",
    dataIndex: "cnic",
    key: "cnic",
    render: (cnic) => {
      if (cnic) {
        return cnic
      } else {
        return "-"
      }
    }
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
    render: (email) => {
      if (email) {
        return email
      } else {
        return "-"
      }
    }
  },
  {
    title: "Location",
    dataIndex: "address",
    key: "address",
  },
];

export const CASHBOOK_COLUMNS: ColumnsType<CashbookI> = [
  // {
  //   title: "Serial No",
  //   dataIndex: "sr",
  //   key: "sr",
  // },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Particulars",
    dataIndex: "particulars",
    key: "particulars",
  },
  {
    title: "Reference Number",
    dataIndex: "referenceNo",
    key: "referenceNo",
  },
  {
    title: "Cash In",
    dataIndex: "cashIn",
    key: "cashIn",
  },
  {
    title: "Cash Out",
    dataIndex: "cashOut",
    key: "cashOut",
  },
  {
    title: "Total Amount",
    dataIndex: "totalAmount",
    key: "totalAmount",
  },
];

// Stockbook Data
export const STOCKBOOK_COLUMNS: ColumnsType<StockbookI> = [
  {
    title: "Item Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Inventory (Qty.)",
    dataIndex: "quantity",
    key: "quantity",
  },
  {
    title: "Rate (Purchase)",
    dataIndex: "purchasePrice",
    key: "purchasePrice",
  },
  {
    title: "Rate (Sale)",
    dataIndex: "sellingPrice",
    key: "sellingPrice",
  },
];

export const STOCKBOOK_ACTION_ITEMS: MenuProps["items"] = [
  // {
  //   label: "In / Buy",
  //   key: "stock-buyIn",
  //   icon: <PlusCircleOutlined className="icon" />,
  // },
  // {
  //   label: "Out / Sell",
  //   key: "stock-buyOut",
  //   icon: <MinusCircleOutlined className="icon" />,
  // },
  {
    label: "Edit",
    key: "edit",
    icon: <EditOutlined className="icon" />,
  },
  {
    label: "Delete",
    key: "delete",
    icon: <DeleteOutlined className="icon" />,
  },
  // {
  //   label: <a href="/customers/stock-book/abcd">View Details</a>,
  //   key: "stock-view",
  //   icon: <EyeOutlined className="icon" />,
  // },
];

// Stockbook details table Data
export const STOCKBOOK_DETAILS_COLUMNS: ColumnsType<StockBookDetailsI> = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Entries",
    dataIndex: "entries",
    key: "entries",
  },
  {
    title: "Stock In",
    dataIndex: "stockIn",
    key: "stockIn",
  },
  {
    title: "Stock Out",
    dataIndex: "stockOut",
    key: "stockOut",
  },
];

export const STOCKBOOK_DETAILS_TABLE_DATA: StockBookDetailsI[] = [
  {
    key: "1",
    date: "1/2/2020",
    entries: "Bought 10 items",
    stockOut: 300,
  },
  {
    key: "2",
    date: "1/2/2020",
    entries: "Bought 10 items",
    stockIn: 200,
  },
  {
    key: "3",
    date: "1/2/2020",
    entries: "Bought 10 items",
    stockOut: 300,
  },
];

export const STAFF_BOOK_COLUMNS: ColumnsType<StaffBookI> = [
  // {
  //   title: "Serial Number",
  //   dataIndex: "sr",
  //   key: "sr",
  // },
  {
    title: "Joining Date",
    dataIndex: "joiningDate",
    key: "joiningDate",
  },
  {
    title: "Employees",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Contact Number",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Working Hours",
    dataIndex: "workingHours",
    key: "workingHours",
  },
  {
    title: "Salary (Rs.)",
    dataIndex: "salary",
    key: "salary",
  },
  {
    title: "Salary Type",
    dataIndex: "salaryType",
    key: "salaryType",
    render: (salaryType) => {
      return (
        <p className="w-min py-2 px-4 rounded-full bg-primary text-white">
          {capitalize(salaryType)}
        </p>
      );
    },
  },
];

export const STAFF_BOOK_ACTION_ITEMS: MenuProps["items"] = [
  {
    label: "Edit",
    key: "edit",
    icon: <EditOutlined className="icon" />,
  },
  {
    label: "Delete",
    key: "delete",
    icon: <DeleteOutlined className="icon" />,
  },
  // {
  //   label: "Switch to Supplier",
  //   key: "3",
  //   icon: <img src={Switch} className="icon" />,
  // },
];

export const BILL_COLUMNS: ColumnsType<BillI> = [
  {
    title: "Item",
    dataIndex: "item",
  },
  {
    title: "Quantity",
    dataIndex: "quantity",
  },
  // {
  //   title: "Rate",
  //   dataIndex: "rate",
  // },
  {
    title: "Amount",
    dataIndex: "amount",
  },
];

export const SUPPLIER_COLUMNS: ColumnsType<SupplierI> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Company",
    dataIndex: "companyName",
    key: "companyName",
  },
  {
    title: "CNIC",
    dataIndex: "cnic",
    key: "cnic",
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Location",
    dataIndex: "address",
    key: "address",
  },
];

export const BILLBOOK_ACTION_ITEMS: MenuProps["items"] = [
  {
    label: "View",
    key: "view",
    icon: <EditOutlined className="icon" />,
  },
  {
    label: "Delete",
    key: "delete",
    icon: <DeleteOutlined className="icon" />,
  },
];
