import { useEffect, useRef, useState } from "react";
import { Pagination } from "antd";
import { BillBookCard } from "./BillBookCard";
import { TableHeader } from "components/Common";
import { RootState, useAppDispatch, useAppSelector } from "store/store";
import { getAllBillbooks } from "store/features/billbook/billbook.service";
import { BillBookI } from "types";

interface BillBookTableI {
  onActionPress?: (type: string, data?: BillBookI) => void;
}

const BillBookTable = ({ onActionPress }: BillBookTableI) => {
  const tableContainerRef = useRef(null);
  const dispatch = useAppDispatch();

  const [type] = useState<string>("supplier");
  const { allBillBooks } = useAppSelector((state: RootState) => state.billbook);

  useEffect(() => {
    dispatch(getAllBillbooks({ type, page: 1, limit: 4 }));
  }, [dispatch]);

  const querySearchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    dispatch(
      getAllBillbooks({
        type,
        page: 1,
        limit: 4,
        search: e.currentTarget.value,
      })
    );
  };

  const onPressHandler = (type: string, data: BillBookI) => {
    onActionPress && onActionPress(type, data);
  };

  return (
    <div className="page-padding display-center-col">
      <div className="table-container">
        <TableHeader
          onChange={querySearchHandler}
          data={allBillBooks?.data}
          tableContainerRef={tableContainerRef}
          placeholder="Search using Bill ID"
        />
        <div ref={tableContainerRef}>
          {!allBillBooks?.data?.length ? (
            <div className="w-full h-auto py-2 px-4">No records found!</div>
          ) : (
            <>
              {allBillBooks.data.map((item, index) => (
                <BillBookCard
                  item={item}
                  key={index}
                  onPress={onPressHandler}
                />
              ))}
              {allBillBooks.data.length > 0 && (
                <div className="flex justify-end">
                  <Pagination
                    pageSize={allBillBooks.limit}
                    total={allBillBooks.totalPages}
                    onChange={(page, pageSize) =>
                      dispatch(getAllBillbooks({ type, page, limit: pageSize }))
                    }
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { BillBookTable };
