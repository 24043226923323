import "styles/login.css";
import BudgyBlackLogo from "assets/images/budgyBlack.svg";
import React, { useEffect, useState } from "react";
import { OTPInput, Button } from "components/Common";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/store";
import { sendOtp, verifyOtp } from "store/features/auth/auth.service";
import { message } from "antd";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { GTagEventType, GTagEventCategory, sendGTagEvent } from "utils/gtag";

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState(1);
  const [userOTP, setUserOTP] = useState<string>("");

  const [phone, setPhone] = useState<any>("+92");
  const [otpCode, setOtpCode] = useState<string>("");
  const [isOtpSend, setIsOtpSend] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(20);

  const sendOTPHandler = () => {
    setLoading(true);
    dispatch(sendOtp({ phone: phone.trim() }))
      .unwrap()
      .then((result: any) => {
        setLoading(false);
        setUserOTP(result.result.otpCode);
        setIsOtpSend(true);
        setTimer(30);
      })
      .catch((error: string) => {
        if (
          error &&
          error === "User validation failed: phone: Invalid phone number"
        ) {
          setLoading(false);
          message.error("Please enter a valid phone number!");
        }
      });
  };

  const verifyOTPHandler = () => {
    setLoading(true);
    dispatch(verifyOtp({ phone, otpCode }))
      .unwrap()
      .then((result: any) => {
        // sending gTag event here...
        sendGTagEvent(GTagEventType.ACCOUNT_LOGIN, GTagEventCategory.ACCOUNT);

        setLoading(false);
        navigate("/dashboard");
      })
      .catch((err: any) => {
        setLoading(false);
        message.error(JSON.stringify(err));
      });
  };

  const resendOTP = () => {
    if (timer === 0) {
      setOtpCode("");
      sendOTPHandler();
    }
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isOtpSend) {
      intervalId = setInterval(() => {
        setTimer((prev) => {
          if (prev > 1) {
            return prev - 1;
          } else {
            clearInterval(intervalId); // Stop the timer when it reaches 0
            return 0;
          }
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalId); // Clean up the interval when the component unmounts
    };
  }, [isOtpSend, otpCode]);

  return (
    <div className="screen-bg w-full h-full mx-auto flex items-center">
      <div className="w-full flex flex-col justify-center items-center px-4 py-12 sm:py-8 sm:px-8">
        <img
          src={BudgyBlackLogo}
          className="w-auto h-[32px] sm:h-[46px] md:h-[56px] mb-8 sm:mb-14"
        />

        <>
          <div className="w-full lg:max-w-[1058px] h-auto bg-[rgba(255,255,255,.8)] rounded-2xl mx-4 sm:mx-10 py-6 px-4 sm:px-10 lg:px-14 sm:py-12 flex justify-center">
            {!isOtpSend && (
              <>
                <div className="hidden md:block flex-1 md:mr-4">
                  <h3 className="font-InterBold text-xl">
                    To use Budgy on Computer:
                  </h3>
                  <p className="font-InterRegular md:text-sm xl:text-lg mt-4">
                    1. Enter Your Phone Number
                  </p>
                  <p className="font-InterRegular md:text-sm xl:text-lg mt-4">
                    2. You will receive 6-digit OTP to verify your number
                  </p>
                  <p className="font-InterRegular md:text-sm xl:text-lg mt-4">
                    3. Enter the 6-digit code and login
                  </p>
                </div>

                <div className="hidden md:block w-[1px] h-auto bg-grey mx-4" />

                <div className="flex-1 md:ml-4">
                  <div className="flex flex-col gap-3">
                    <h3 className="font-InterBold text-xl">
                      Enter Your Phone Number
                    </h3>
                    <PhoneInput
                      className="w-full"
                      defaultCountry={"PK"}
                      placeholder="Enter phone number"
                      value={phone}
                      onChange={(val: string) => {
                        if (val?.length <= 13) {
                          setPhone(val);
                        }
                      }}
                      limitMaxLength={true}
                    />
                    <Button
                      title="Get Pin"
                      loading={loading}
                      onClick={sendOTPHandler}
                    />
                  </div>
                </div>
              </>
            )}

            {isOtpSend && (
              <div className="flex flex-col justify-center items-center p-6 sm:p-0">
                <h3 className="font-InterBold text-sm sm:text-xl mb-8">
                  OTP {userOTP} Sent to {phone}
                </h3>

                <OTPInput
                  autoFocus
                  length={6}
                  className="flex gap-2 sm:gap-4"
                  inputClassName="w-[38px] h-[38px] sm:w-[56px] sm:h-[56px] bg-white border text-primary text-lg text-center rounded-full font-InterBold outline-primary"
                  onChangeOTP={setOtpCode}
                />

                <div className="w-full flex flex-col sm:flex-row justify-between items-center mt-8 mb-14">
                  {timer > 0 ? (
                    <p className="font-InterRegular text-xs sm:text-lg mt-4">
                      Resend code in {timer} seconds
                    </p>
                  ) : (
                    <Link
                      to={"#"}
                      onClick={resendOTP}
                      className="font-InterRegular underline text-primary text-xs sm:text-lg mt-4"
                    >
                      Click to resend OTP
                    </Link>
                  )}

                  <Link
                    to={""}
                    onClick={() => {
                      setIsOtpSend(false);
                      setPhone("");
                      setTimer(20);
                    }}
                    className="font-InterRegular underline text-primary text-xs sm:text-lg mt-4"
                  >
                    Change Number
                  </Link>
                </div>
                <Button
                  title="Login"
                  loading={loading}
                  disabled={otpCode.length <= 5}
                  onClick={verifyOTPHandler}
                />
              </div>
            )}
          </div>
          <div className="flex flex-col gap-6 max-w-[1020px] mx-4 sm:mx-10 px:2 sm:px-14 py-6 sm:py-12 text-xs sm:text-sm text-center">
            <div className="flex items-center justify-center gap-5 ">
              <h3
                onClick={() => setSelectedLanguage(1)}
                className={`cursor-pointer ${
                  selectedLanguage === 1
                    ? "font-bold underline underline-offset-2"
                    : "font-normal"
                }`}
              >
                English
              </h3>
              <h3
                onClick={() => setSelectedLanguage(2)}
                className={`cursor-pointer ${
                  selectedLanguage === 2
                    ? "font-bold underline underline-offset-2"
                    : "font-normal"
                }`}
              >
                Urdu
              </h3>
            </div>
            <div className="">
              By signing up or connecting with the services above you agree to
              our{" "}
              <span className="text-white font-InterSemiBold underline cursor-pointer">
                Terms of Services
              </span>{" "}
              and acknowledge our{" "}
              <span className="text-white font-InterSemiBold underline cursor-pointer">
                Privacy Policy
              </span>{" "}
              describing how we handle your personal data.
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

export default Login;
