import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { Header } from "components/Common";
import {
  AddStockModal,
  StockBookTable,
  StockInModal,
  StockOutModal,
} from "components/Customer/StockBook";
import { useAppDispatch } from "store/store";
import { StockbookI } from "types";
import { deleteStockbook } from "store/features/stockbook/stockbook.service";
import { GTagEventCategory, GTagEventType, sendGTagEvent } from "utils/gtag";

const StockBook: React.FC = () => {
  const dispatch = useAppDispatch();
  const [modalType, setModalType] = useState<"add" | "edit">("add");

  const [addStockModalOpen, setAddStockModalOpen] = useState(false);
  const [stockInModal, setStockInModal] = useState(false);
  const [stockOutModal, setStockOutModal] = useState(false);

  const [defaultValues, setDefaultValues] = useState<StockbookI | null>(null);

  // Reusable function to close modals
  const closeModal = (setter: (val: boolean) => void) => () => setter(false);

  const handleAddStockModalClose = closeModal(setAddStockModalOpen);
  const handleStockInModalClose = closeModal(setStockInModal);
  const handleStockOutModalClose = closeModal(setStockOutModal);

  return (
    <div className="page-padding">
      <Header heading="Stock Book">
        <Button
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalType("add");
            setAddStockModalOpen(true);
          }}
        >
          Add Stock
        </Button>
      </Header>
      <StockBookTable
        setStockInModal={setStockInModal}
        setStockOutModal={setStockOutModal}
        onActionPress={(type, data) => {
          setDefaultValues(data);

          if (type.key === "edit") {
            setModalType("edit");
            setAddStockModalOpen(true);
          }

          if (type.key === "delete") {
            dispatch(deleteStockbook({ id: data.id }));
            message.success("Stockbook record has been deleted successfully!");

            // sending gTag event here...
            sendGTagEvent(
              GTagEventType.DELETE_STOCK,
              GTagEventCategory.STOCKBOOKS_CUSTOMERS
            );
          }
        }}
      />
      <AddStockModal
        type={modalType}
        open={addStockModalOpen}
        onCancel={() => {
          setDefaultValues(null);
          handleAddStockModalClose();
        }}
        defaultValues={defaultValues}
      />
      <StockInModal open={stockInModal} onCancel={handleStockInModalClose} />
      <StockOutModal open={stockOutModal} onCancel={handleStockOutModalClose} />
    </div>
  );
};

export { StockBook };
