import { useEffect } from "react";
import { Button, Col, Modal, Row, message } from "antd";
import { CustomInput } from "components/Elements";
import { Controller, useForm } from "react-hook-form";
import {
  addCustomer,
  updateCustomer,
} from "store/features/customer/customer.service";
import { useAppDispatch } from "store/store";
import { AddSupplierI } from "types";
import { yupResolver } from "@hookform/resolvers/yup";
import { createSupplierSchema } from "validations";
import { formatCNIC } from "utils/text";
import { GTagEventCategory, GTagEventType, sendGTagEvent } from "utils/gtag";

const defaultSuppliersData: AddSupplierI = {
  name: "",
  phone: "",
  companyName: "",
  cnic: "",
  email: "",
  address: "",
  details: "",
  type: "supplier",
};

type AddSupplierModalI = {
  open: boolean;
  type: "edit" | "add";
  defaultValues?: (AddSupplierI & { id: string }) | null;
  onCancel: () => void;
};

const AddSupplierModal = ({
  open,
  type,
  defaultValues,
  onCancel,
}: AddSupplierModalI) => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(createSupplierSchema),
  });

  const onSubmit = (data: AddSupplierI) => {
    if (type === "add") {
      dispatch(addCustomer({ data, type: "supplier" }))
        .unwrap()
        .then(() => {
          reset();
          message.success("Supplier has been created successfully!");
          onCancel();

          // sending gTag event here...
          sendGTagEvent(
            GTagEventType.ADD_SUPPLIER,
            GTagEventCategory.SUPPLIERS
          )
        })
        .catch((errMessage: string) => {
          message.error(errMessage);
        });
    }

    if (type === "edit") {
      dispatch(
        updateCustomer({
          id: defaultValues?.id,
          data,
          type: "supplier",
        })
      )
        .unwrap()
        .then(() => {
          reset();
          message.success("Supplier has been updated successfully!");
          onCancel();

          // sending gTag event here...
          sendGTagEvent(
            GTagEventType.UPDATE_SUPPLIER,
            GTagEventCategory.SUPPLIERS
          )
        })
        .catch((errMessage: string) => {
          message.error(errMessage);
        });
    }
  };

  // @ts-ignore
  const addCustomerHandler = () => handleSubmit(onSubmit)();

  useEffect(() => {
    if (type === "add") {
      reset(defaultSuppliersData);
    } else if (type === "edit" && defaultValues) {
      reset({
        name: defaultValues.name,
        phone: defaultValues.phone,
        companyName: defaultValues.companyName,
        cnic: defaultValues.cnic,
        email: defaultValues.email,
        address: defaultValues.address,
        details: defaultValues.details
      });
    }
  }, [type, defaultValues]);

  return (
    <Modal
      title={`${type === "add" ? "Add" : "Edit"} Supplier`}
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={668}
      footer={[
        <Button
          size="large"
          type="primary"
          style={{ marginTop: "1rem" }}
          onClick={addCustomerHandler}
          htmlType="submit"
        >
          {type === "add" ? "Add" : "Update"}
        </Button>,
      ]}
    >
      <div className="modal">
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    label="Name"
                    placeholder="Enter your name"
                    {...field}
                  />
                  {errors.name && (
                    <p className="fieldValidationError">
                      {errors.name.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    label="Phone Number"
                    placeholder="+923XXXXXXXXX"
                    maxLength={13}
                    {...field}
                  />
                  {errors.phone && (
                    <p className="fieldValidationError">
                      {errors.phone.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="companyName"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    label="Company Name"
                    placeholder="Enter Company Name"
                    {...field}
                  />
                  {errors.companyName && (
                    <p className="fieldValidationError">
                      {errors.companyName.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="cnic"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    label="CNIC"
                    placeholder="XXXXX-XXXXXXX-X"
                    {...field}
                    onChange={(e) => field.onChange(formatCNIC(e.target.value))}
                  />
                  {errors.cnic && (
                    <p className="fieldValidationError">
                      {errors.cnic.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <CustomInput
                    label="Email Address"
                    placeholder="Enter email address"
                    {...field}
                  />
                )}
              />
              {errors.email && (
                <p className="fieldValidationError">{errors.email.message}</p>
              )}
            </>
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="address"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    label="Location"
                    placeholder="Enter location"
                    {...field}
                  />
                  {errors.address && (
                    <p className="fieldValidationError">
                      {errors.address.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col span={24}>
            <Controller
              name="details"
              control={control}
              render={({ field }) => (
                <>
                  <CustomInput
                    label="Details (optional)"
                    placeholder="Further Info"
                    {...field}
                  />
                  {errors.details && (
                    <p className="fieldValidationError">
                      {errors.details.message}
                    </p>
                  )}
                </>
              )}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export { AddSupplierModal };
