import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sendOtp, verifyOtp } from "./auth.service";
import { AppThunk, RootState } from "store/store";
import { isExpired } from "utils/date";

export interface SendOTPI {
  user: {
    role: string;
    isDeleted: boolean;
    phone: string;
    id: string;
  };
  token: {
    token: string;
    expires: string;
  };
}

export interface SendOTPResponse {
  loading: boolean;
  data: SendOTPI | null;
  error: string | null;
  stack: string | null;
}

const initialState: SendOTPResponse = {
  loading: false,
  data: null,
  error: null,
  stack: null,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk('counter/fetchCount', async (amount: number) => {
//     const response = await fetchCount(amount);
//     // The value we return becomes the `fulfilled` action payload
//     return response.data;
//   }
// );

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    // setTodos: (state, action: PayloadAction<TodosI[]>) => {
    //   state.data = action.payload;
    // },
    // resetTodos: (state) => {
    //   state.loading = false;
    //   state.error = undefined;
    //   state.data = [];
    // },
    logoutUser: () => {
      return initialState;
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      // sendOtp
      .addCase(sendOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.loading = false;
      })
      // verifyOtp
      .addCase(verifyOtp.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOtp.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload.result;
      })
      .addCase(verifyOtp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { logoutUser } = authSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
// export const selectCount = (state: RootState) => state.counter.value;

export const userState = (state: RootState) => state.user;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd = (amount: number): AppThunk => (dispatch, getState) => {
//     const currentValue = selectCount(getState());
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount));
//     }
//   };

export default authSlice.reducer;
