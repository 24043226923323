import React, { useEffect, useState } from "react";
import { Select as AntSelect } from "antd";
import { RootState, useAppDispatch, useAppSelector } from "store/store";
import { getAllCustomers } from "store/features/customer/customer.service";

// Filter `option.label` match the user type `input`
const filterOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

interface CustomAutoCompleteInputI {
  label?: string;
  placeholder?: string;
  value?: { label: string; value: string };
  onSelect?: ({label, value}: {label: string, value: string}) => void;
  type?: "customer" | "supplier"
}

const Select = ({
  label,
  placeholder,
  value: defaultValue,
  onSelect,
  type = "customer"
}: CustomAutoCompleteInputI) => {
  
  const dispatch = useAppDispatch();
  const { loading, allCustomers } = useAppSelector(
    (state: RootState) => state.customer
  );

  const [value, setValue] = useState<
    string | { label: string; value: string } | null
  >(null);
  const [options, setOptions] = useState<{ label: string; value: string }[]>(
    []
  );

  const onChange = (data: string) => {
    if (!data) {
      setValue(data);
      onSelect && onSelect(null as any);
      return;
    }
    setValue(data);
  };

  const onSearch = (text: string) => {
    // Filter the options based on name or phone number
    const filteredOptions = allCustomers?.data
      .filter(
        (el) =>
          el.name.toLowerCase().includes(text.toLowerCase()) ||
          el.phone.includes(text)
      )
      .map((el) => ({
        label: `${el.name} (${el.phone})`,
        value: el.id,
      }));

    setOptions(filteredOptions || []);
  };

  useEffect(() => {
    let cList = allCustomers?.data.map((el) => ({
      label: `${el.name} (${el.phone})`,
      value: el.id,
    }));

    setOptions(cList || []);
  }, [loading]);

  useEffect(() => {
    dispatch(getAllCustomers({ type: type, page: 1, limit: 10000 }));
  }, [dispatch]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      setValue(null);
    }
  }, [defaultValue]);

  return (
    <div className="custom-autocomplete flex flex-col w-full mb-8">
      <label className="text-base font-medium leading-[20px] mb-2">
        {label}
      </label>
      <AntSelect
        options={options}
        onSearch={onSearch}
        onSelect={(selectedValue, option) => {
          setValue(option as any);
          onSelect && onSelect(option);
        }}
        placeholder={placeholder}
        onChange={onChange}
        filterOption={filterOption}
        showSearch
        value={value as { label: string; value: string } as any}
      />
    </div>
  );
};

export { Select };
