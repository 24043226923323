import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/httpService";
import { AddStaffbookI, UpdateStaffbookI } from "types";

export const getAllStaffbooks: any = createAsyncThunk(
  "staffbook/getAllStaffbooks",
  async (
    {
      page = 1,
      limit = 10,
      type,
      search = null,
    }: {
      page: number;
      limit: number;
      type: string;
      search: string | number | null;
    },
    { rejectWithValue }: any,
  ) => {
    try {      
      const response = await axiosInstance.get(
        `/staffbook/list?page=${page}&limit=${limit}&type=${type}${
          search ? "&search=" + search : ""
        }`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue("Something went wrong at backend!");
    }
  },
);

export const addStaffbook: any = createAsyncThunk(
  "staffbook/addStaffbook",
  async (
    {
      data,
      type,
    }: { data: AddStaffbookI; type?: "customer" | "supplier" },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.post(`/staffbook/create`, {
        ...data,
        type,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const updateStaffbook: any = createAsyncThunk(
  "staffbook/updateStaffbook",
  async (
    { id, data }: { id: string; data: UpdateStaffbookI },

    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.patch(`/staffbook/${id}`, {
        ...data,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const deleteStaffbook: any = createAsyncThunk(
  "staffbook/deleteStaffbook",
  async ({ id }: { id: string }, { rejectWithValue }: any) => {
    try {
      const response = await axiosInstance.delete(`/staffbook/${id}`);
      return { ...response.data, deletedId: id };
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);
