import { PlusOutlined } from "@ant-design/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, message } from "antd";
import { Header } from "components/Common";
import {
  AddItemsModal,
  AddNewItemModal,
  BillBookTable,
  BillModal,
  CreateBillModal,
  SelectedStockbookI,
} from "components/Customer/BillBook";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { addBillbook, deleteBillbook } from "store/features/billbook/billbook.service";
import { useAppDispatch } from "store/store";
import { AddBillbookI, BillBookI } from "types";
import { GTagEventCategory, GTagEventType, sendGTagEvent } from "utils/gtag";
import { createBillbookSchema } from "validations";

const BillBook = () => {
  const dispatch = useAppDispatch();
  const { control, handleSubmit, reset, setValue, formState } = useForm({
    resolver: yupResolver(createBillbookSchema),
  });

  const [creatBillModal, setCreateBillModal] = useState<boolean>(false);
  const [addItemsModal, setAddItemsModal] = useState<boolean>(false);
  const [addNewItemModal, setAddNewItemModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<SelectedStockbookI[]>([]);

  const [activeRow, setActiveRow] = useState<BillBookI | null>(null);
  const [showBillDetails, setShowBillDetails] = useState<boolean>(false);

  const onClickCreateBill = () => setCreateBillModal(true);

  const onSubmit = (data: AddBillbookI) => {
    data.item = selectedItem.map((el) => ({
      id: el.id,
      quantity: el.mQuantity,
      item: el.name,
      amount: el.sellingPrice,
    }));

    data.userId = data?.userId?.value ?? null

    dispatch(addBillbook({ data: { ...data, photos: [] }, type: "customer" }))
      .unwrap()
      .then(() => {
        // sending gTag event here...
        sendGTagEvent(
          GTagEventType.ADD_BILL,
          GTagEventCategory.BILLBOOKS_CUSTOMERS
        );

        reset();
        message.success("Bill record has been created successfully!");
        setCreateBillModal(false);
        setSelectedItem([]);
        reset();
      })
      .catch((errMessage: string) => {
        message.error(errMessage);
      });
  };

  // @ts-ignore
  const createBillHandler = () => handleSubmit(onSubmit)();

  useEffect(() => {
    // Calculate total amount when selected items change
    let totalAmount = 0;
    selectedItem.forEach((item) => {
      if (item?.sellingPrice) {
        totalAmount += item.sellingPrice * item.mQuantity;
      }
    });

    // Set the total amount as the default value in the "amount" field
    setValue("amount" as any, totalAmount);
  }, [selectedItem, setValue]);

  return (
    <div className="page-padding">
      <Header heading="Bill Book">
        <Button
          type="primary"
          size="large"
          icon={<PlusOutlined />}
          onClick={onClickCreateBill}
        >
          Create New Bill
        </Button>
      </Header>

      <BillBookTable
        onActionPress={(type, data) => {
          if (type === "view" && data) {
            setActiveRow(data);
            setShowBillDetails(true);
          }

          if (type === "delete" && data) {
            dispatch(deleteBillbook({ id: data.id }));
            message.success("Bill has been deleted successfully!");

            // sending gTag event here...
            // sendGTagEvent(
            //   GTagEventType.DELETE_BILL,
            //   GTagEventCategory.BILLBOOKS_CUSTOMERS
            // );
          }
        }}
      />

      <CreateBillModal
        formState={formState}
        control={control}
        open={creatBillModal}
        items={selectedItem}
        setItems={setSelectedItem}
        onCancel={() => {
          setCreateBillModal(false);
          setSelectedItem([]);
          reset();
        }}
        onClickItems={() => {
          setCreateBillModal(false);
          setAddItemsModal(true);
        }}
        onClickSave={() => {
          createBillHandler();
          // setCreateBillModal(false);
          // setBillModal(true);
          // addBillHandler()
        }}
      />

      <AddItemsModal
        open={addItemsModal}
        onCancel={() => setAddItemsModal(false)}
        selectedItems={selectedItem}
        onClickSave={(data: SelectedStockbookI[]) => {
          setSelectedItem(data);
          setAddItemsModal(false);
          setCreateBillModal(true);
        }}
        onClickAddNewItem={() => {
          setCreateBillModal(false);
          setAddItemsModal(false);
          setAddNewItemModal(true);
        }}
      />

      <AddNewItemModal
        open={addNewItemModal}
        onCancel={() => setAddNewItemModal(false)}
        onClickSave={() => {
          setAddNewItemModal(false);
          setAddItemsModal(true);
        }}
      />

      {activeRow && (
        <BillModal
          data={activeRow}
          open={showBillDetails}
          onCancel={() => setShowBillDetails(false)}
        />
      )}
    </div>
  );
};

export default BillBook;
