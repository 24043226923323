import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/httpService";

export const sendOtp: any = createAsyncThunk(
  "auth/sendOtp",
  async (payload: { phone: string }, { rejectWithValue }: any) => {
    try {
      const response = await axiosInstance.post("/auth/send-otp", {
        phone: payload.phone,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const verifyOtp: any = createAsyncThunk(
  "auth/verifyOtp",
  async (
    payload: { phone: string; otpCode: string },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.post("/auth/verify-otp", {
        phone: payload.phone,
        otpCode: payload.otpCode,
      });

      if (response.data?.result?.token?.token) {
        localStorage.setItem("USER_ACCESS_TOKEN", response.data?.result?.token?.token)
      }

      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.data.error);
    }
  },
);
