import { Table } from "antd";
import { TableHeader } from "components/Common";
import { STOCKBOOK_DETAILS_COLUMNS, STOCKBOOK_DETAILS_TABLE_DATA } from "const";

const StockBookDetailsTable = () => {
  return (
    <div className="page-padding display-center-col">
      <div className="table-container">
        <TableHeader actionBtns={false} />
        <Table
          columns={STOCKBOOK_DETAILS_COLUMNS}
          dataSource={STOCKBOOK_DETAILS_TABLE_DATA}
          pagination={false}
          className="customer-table stock-details-table"
        />
      </div>
    </div>
  );
};

export { StockBookDetailsTable };
