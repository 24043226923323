import moment from "moment";

export const isExpired = (iat: string) => {
  const expiryDate = new Date(iat);
  const currentDate = new Date();
  return currentDate >= expiryDate;
};

export const getDateFormat = (date: string) => {
  let d = moment(date).format("DD-MM-YYYY");
  return d;
};
