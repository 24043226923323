import React from "react";
import { Typography } from "antd";
import { Chevronleft } from "assets/icons";
import { StockBookDetailsTable } from "components/Customer/StockBook";
import { useNavigate } from "react-router-dom";

const StockItemDetails = () => {
  const navigate = useNavigate();
  return (
    <div className="page-padding">
      <div className="stock-item-details-header">
        <img
          src={Chevronleft}
          className="back-btn"
          onClick={() => navigate(-1)}
        />
        <div className="headings">
          <Typography.Title level={3} className="heading">
            Pens (Stock In Hand)
          </Typography.Title>
          <Typography.Paragraph className="sub-heading">
            4 (Pcs)
          </Typography.Paragraph>
        </div>
      </div>
      <StockBookDetailsTable />
    </div>
  );
};

export { StockItemDetails };
