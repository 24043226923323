import * as yup from "yup";

export const createBillbookSchema = yup.object().shape({
  //   item: yup
  //     .array()
  //     .of(
  //       yup.object().shape({
  //         id: yup.string(),
  //         quantity: yup.number().integer().positive(),
  //       }),
  //     )
  //     .required(),
  details: yup.string().optional(),
  photos: yup.array().of(yup.string()).optional(),
  date: yup.string().required("Date is required"),
  billType: yup.string().required("Bill Type is required"),
  type: yup.string(),
});
