import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/httpService";
import { AddStockbookI, UpdateStockbookI } from "types";

export const getAllStockbooks: any = createAsyncThunk(
  "stockbook/getAllStockbooks",
  async (
    {
      page = 1,
      limit = 10,
      type,
      search = null
    }: { page: number; limit: number; type: string, search: string | number | null },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.get(
        `/stockbook/list?page=${page}&limit=${limit}${type ? "&type=" + type : ""}${search ? "&search=" + search : ""}`,
      );
      return response.data;
    } catch (err) {
      return rejectWithValue("Something went wrong at backend!");
    }
  },
);


export const addStockbook: any = createAsyncThunk(
  "stockbook/addStockbook",
  async (
    {
      data,
      type = "customer",
    }: { data: AddStockbookI; type?: "customer" | "supplier" },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.post(`/stockbook/create`, {
        ...data,
        type,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const updateStockbook: any = createAsyncThunk(
  "stockbook/updateStockbook",
  async (
    {
      id,
      data,
      type = "customer",
    }: { id: string; data: UpdateStockbookI; type?: "customer" | "supplier" },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.patch(`/stockbook/${id}`, {
        ...data,
        type,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const deleteStockbook: any = createAsyncThunk(
  "stockbook/deleteStockbook",
  async ({ id }: { id: string }, { rejectWithValue }: any) => {
    try {
      const response = await axiosInstance.delete(`/stockbook/${id}`);
      return { ...response.data, deletedId: id };
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);
