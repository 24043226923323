import React, { useEffect } from "react";
import { Loader } from "components/Common";
import { useAppDispatch, useAppSelector } from "store/store";
import { listAnalytics } from "store/features/analytics/analytics.service";
import { TeamOutlined, UserSwitchOutlined } from "@ant-design/icons";
import { TotalSalesWidget } from "components/widgets/TotalSalesWidget/TotalSalesWidgets";
import { RecentStockWidget } from "components/widgets/RecentStockWidget/RecentStockWidget";
import { MonthlyRevenueWidget } from "components/widgets/MonthlyRevenueWidget/MonthlyRevenueWidget";
import { StatsCardWidget } from "components/widgets/StatsCardWidget/StatsCardWidget";

const Dashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, loading } = useAppSelector((state) => state.analytics);

  useEffect(() => {
    dispatch(listAnalytics());
  }, []);

  return (
    <div className="w-full h-full pt-5 rounded-lg">
      {loading ? (
        <div className="w-full h-[calc(100vh-250px)] flex justify-center items-center">
          <Loader />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">
            <StatsCardWidget
              label="Total Customers"
              value={data?.totalCustomers.value}
              change={data?.totalCustomers?.change}
              icon={<UserSwitchOutlined className="text-3xl" />}
            />
            <StatsCardWidget
              label="New Customers"
              value={data?.newCustomers.value}
              icon={<TeamOutlined className="text-3xl" />}
            />
            <StatsCardWidget
              label="Total Suppliers"
              value={data?.totalSuppliers.value}
              change={data?.totalSuppliers.change}
              icon={<UserSwitchOutlined className="text-3xl" />}
            />
            <StatsCardWidget
              label="New Suppliers"
              value={data?.newSuppliers.value}
              icon={<TeamOutlined className="text-3xl" />}
            />
          </div>

          <div className="mt-4 grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="col-span-1 md:col-span-2">
              <TotalSalesWidget
                label="Total Sales"
                data={data.weeklyRevenueReport}
              />
            </div>
            <div className="col-span-1 md:col-span-1">
              <RecentStockWidget
                label="Recent Stocks"
                data={data.recentStockUpdates}
              />
            </div>
          </div>

          <div className="mt-4 pb-20">
            <MonthlyRevenueWidget
              label="Monthly Revenue Reports"
              data={data?.monthlyRevenueReports}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
