import React, { HTMLAttributes } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Define the interface extending HTMLButtonElement
interface ButtonI extends HTMLAttributes<HTMLButtonElement> {
  title: string;
  loading?: boolean;
  disabled?: boolean
}

// Destructure 'title' from the props, and use 'rest' for other props
const Button: React.FC<ButtonI> = ({ title, loading, disabled = false, ...rest }) => {
  return (
    <button
      className={`w-full ${
        loading ? "bg-grey" : "bg-black"
      } py-3 px-10 rounded-md text-white ${
        loading ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      disabled={disabled || loading}
      {...rest}
    >
      <div className="flex justify-center items-center gap-4">
        {loading ? (
          <Spin
            indicator={
              <LoadingOutlined
                style={{ fontSize: 24 }}
                className="text-black"
                spin
              />
            }
          />
        ) : (
          title
        )}
      </div>
    </button>
  );
};

export { Button };
