import { useEffect } from "react";
import { Button, Col, Modal, Radio, Row } from "antd";
import { CustomInput } from "components/Elements";
import { Control, Controller, UseFormReset } from "react-hook-form";
import { AddStaffbookI } from "types";

const defaultStaffbookData: AddStaffbookI = {
  joiningDate: "",
  name: "",
  phone: "",
  salary: 0,
  salaryType: "",
  workingHours: 0,
};

type AddStaffModalPropTypes = {
  type: "add" | "edit";
  reset: UseFormReset<AddStaffbookI>;
  control: Control<any>;
  open: boolean;
  onCancel: () => void;
  onClickSave: () => void;
  defaultValues?: (AddStaffbookI & { id: string }) | null;
};

const AddStaffModal = ({
  type,
  reset,
  control,
  open,
  onCancel,
  onClickSave,
  defaultValues,
}: AddStaffModalPropTypes) => {
  useEffect(() => {
    if (type === "add") {
      reset(defaultStaffbookData);
    } else if (type === "edit" && defaultValues) {
      reset({
        joiningDate: defaultValues.joiningDate,
        name: defaultValues.name,
        phone: defaultValues.phone,
        salary: defaultValues.salary,
        salaryType: defaultValues.salaryType,
        workingHours: defaultValues.workingHours,
      });
    }
  }, [type, defaultValues]);

  return (
    <Modal
      title={type === "add" ? "Add Staff" : "Edit Staff"}
      open={open}
      onOk={onCancel}
      onCancel={onCancel}
      width={668}
      footer={[
        <Button
          size="large"
          type="primary"
          style={{ marginTop: "1rem" }}
          onClick={onClickSave}
        >
          Save
        </Button>,
      ]}
    >
      <div className="modal">
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="name"
              control={control}
              render={({ field }: any) => (
                <CustomInput
                  label="Employee Name"
                  placeholder="Name"
                  {...field}
                />
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="phone"
              control={control}
              render={({ field }: any) => (
                <CustomInput
                  label="Phone Number"
                  placeholder="+923XXXXXXXXX"
                  maxLength={13}
                  {...field}
                />
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="workingHours"
              control={control}
              render={({ field }: any) => (
                <CustomInput
                  label="Working Hours"
                  placeholder="working hours"
                  {...field}
                  value={field.value ? String(field.value) : ""}
                  onChange={(e) => {
                    const numericValue = parseInt(e.target.value);
                    field.onChange(isNaN(numericValue) ? 0 : numericValue);
                  }}
                />
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="joiningDate"
              control={control}
              render={({ field }) => (
                <CustomInput
                  type="date"
                  label="Date"
                  placeholder="dd/mm/yyyy"
                  {...field}
                  onChange={(e) => field.onChange(e.target.value)}
                />
              )}
            />
          </Col>
        </Row>
        <Row gutter={[28, 28]}>
          <Col xs={24} md={12}>
            <Controller
              name="salary"
              control={control}
              render={({ field }: any) => (
                <CustomInput
                  label="Salary"
                  type="number"
                  placeholder="Enter Staff Salary per month"
                  {...field}
                  value={String(field.value)}
                  onChange={(e) => {
                    const numericValue = parseInt(e.target.value);
                    field.onChange(isNaN(numericValue) ? 0 : numericValue);
                  }}
                />
              )}
            />
          </Col>
          <Col xs={24} md={12}>
            <Controller
              name="salaryType"
              control={control}
              render={({ field }) => (
                <>
                  <div className="text-base mb-1">Salary Type</div>
                  <Radio.Group {...field} className="flex flex-wrap gap-1">
                    <Radio value="monthly">Monthly</Radio>
                    <Radio value="weekly">Weekly</Radio>
                    <Radio value="daily">Daily</Radio>
                    <Radio value="hourly">Hourly</Radio>
                  </Radio.Group>
                </>
              )}
            />
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export { AddStaffModal };
