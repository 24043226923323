import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Select, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ChevronDown } from "assets/icons";
import { TableHeader } from "components/Common";
import { SUPPLIER_COLUMNS, CUSTOMER_ACTION_ITEMS as items } from "const";
import { getAllCustomers } from "store/features/customer/customer.service";
import { RootState, useAppDispatch, useAppSelector } from "store/store";
import { CustomerI, SupplierI } from "types";

const filterOptions = [
  { label: "Suppliers (A-Z)", value: "name_asc" },
  { label: "Suppliers (Z-A)", value: "name_desc" },
  { label: "Recently added suppliers", value: "recently_added" },
  { label: "Highest paid suppliers", value: "heighest_amount_paid" },
  {
    label: "Suppliers with most outstanding dues",
    value: "heighest_amount_due",
  },
  { label: "Suppliers with zero dues", value: "zero_amount_due" },
];

interface SuppliersTableI {
  onActionPress?: (type: { key: string }, data?: any) => void;
}

const SuppliersTable = ({ onActionPress }: SuppliersTableI) => {
  const dispatch = useAppDispatch();

  const tableContainerRef = useRef(null);
  const [type] = useState<string>("supplier");
  const [activeRow, setActiveRow] = useState<CustomerI | null>(null);
  const [filter, setFilter] = useState<string | null>(null);
  const [search, setSearch] = useState("");
  const { loading, allCustomers } = useAppSelector(
    (state: RootState) => state.customer
  );

  useEffect(() => {
    dispatch(
      getAllCustomers({
        type,
        page: 1,
        limit: 10,
        // filterBy: filter,
        search: search,
      })
    );
  }, [search, filter]);

  useEffect(() => {
    dispatch(getAllCustomers({ type, page: 1, limit: 10 }));
  }, [dispatch]);

  const menuProps = {
    items,
    onClick: (type: { key: string }) => {
      onActionPress && onActionPress(type, activeRow);
    },
  };

  const TABLE_COLUMNS: ColumnsType<SupplierI> = [
    {
      title: "Sr.",
      key: "serial",
      render: (text, record, index) => {
        const current = allCustomers?.page || 1;
        const pageSize = allCustomers?.limit || 10;
        const sr = (current - 1) * pageSize + index + 1;
        return sr;
      },
    },
    ...SUPPLIER_COLUMNS,
    {
      title: "Action",
      key: "action",
      render: (props) => (
        <Dropdown
          menu={menuProps as any}
          trigger={"click" as any}
          onOpenChange={(open: boolean) => {
            if (open) {
              setActiveRow(props);
            }
          }}
        >
          <Button className="action-btn">
            Actions
            <img src={ChevronDown} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const handleFilterChange = (value: string) => {
    setFilter(value);
  };
  const handleSearch = (e: any) => {
    setSearch(e?.currentTarget?.value);
  };

  return (
    <div className="page-padding display-center-col">
      <div className="table-container">
        <TableHeader
          // filterOptions={filterOptions}
          placeholder="Search Supplier"
          onChange={handleSearch}
          searchValue={search}
          handleFilterChange={handleFilterChange}
          filterSelected={filter}
          data={allCustomers?.data}
          tableContainerRef={tableContainerRef}
        />
        <div ref={tableContainerRef} className="overflow-y-auto">
          <Table
            loading={loading}
            columns={TABLE_COLUMNS as any}
            dataSource={allCustomers?.data}
            className="table"
            rowKey={(record) => record.id}
            pagination={{
              pageSize: allCustomers?.limit,
              total: allCustomers?.totalPages,
              onChange: (page, pageSize) => {
                dispatch(getAllCustomers({ type, page, limit: pageSize }));
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { SuppliersTable };
