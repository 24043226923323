import "styles/customer.css";
import { UsergroupAddOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { Header } from "components/Common";
import {
  AddSupplierModal,
  SuppliersTable,
} from "components/Supplier/AllSupplier";
import React, { useState } from "react";
import { deleteCustomer } from "store/features/customer/customer.service";
import { useAppDispatch } from "store/store";
import { SupplierI } from "types";
import { GTagEventCategory, GTagEventType, sendGTagEvent } from "utils/gtag";

const AllCustomers: React.FC = () => {
  const dispatch = useAppDispatch();
  const [modalType, setModalType] = useState<"add" | "edit">("edit");
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [defaultValues, setDefaultValues] = useState<SupplierI | null>(null);

  return (
    <div className="page-padding">
      <Header heading="All Suppliers">
        <Button
          type="primary"
          size="large"
          icon={<UsergroupAddOutlined />}
          onClick={() => {
            setDefaultValues(null);
            setModalType("add");
            setShowAddModal(true);
          }}
        >
          Add Supplier
        </Button>
      </Header>
      <SuppliersTable
        onActionPress={(type, data) => {
          setDefaultValues(data);

          if (type.key === "edit") {
            setModalType("edit");
            setShowAddModal(true);
          }

          if (type.key === "delete") {
            dispatch(deleteCustomer({ id: data.id }));
            message.success("Customer has been deleted successfully!");

            // sending gTag event here...
            sendGTagEvent(
              GTagEventType.DELETE_SUPPLIER,
              GTagEventCategory.SUPPLIERS
            );
          }
        }}
      />
      <AddSupplierModal
        type={modalType}
        open={showAddModal}
        onCancel={() => {
          setDefaultValues(null);
          setShowAddModal(false);
        }}
        defaultValues={defaultValues}
      />
    </div>
  );
};

export default AllCustomers;
