import { createSlice } from "@reduxjs/toolkit";
import {
  addStaffbook,
  deleteStaffbook,
  getAllStaffbooks,
  updateStaffbook,
} from "./staffbook.service";
import { StaffBookI } from "types";

export interface AllStaffbooksI {
  data: StaffBookI[];
  page: number;
  limit: number;
  hasNext: boolean;
  totalPages: number;
}

export interface BillbookStoreI {
  error: string | null;
  loading: boolean;
  allStaffBooks: AllStaffbooksI | null;
}

const initialState: BillbookStoreI = {
  loading: false,
  error: null,
  allStaffBooks: {
    data: [],
    page: 1,
    limit: 10,
    hasNext: false,
    totalPages: 1,
  },
};

export const staffbookSlice = createSlice({
  name: "staffbooks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get All Staffbooks
      .addCase(getAllStaffbooks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStaffbooks.fulfilled, (state, action) => {
        state.loading = false;
        state.allStaffBooks = action.payload.result;
      })
      .addCase(getAllStaffbooks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add New Staffbook
      .addCase(addStaffbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStaffbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allStaffBooks = {
          ...state.allStaffBooks,
          data: [
            action.payload.result.staffbook,
            ...(state.allStaffBooks?.data as StaffBookI[]),
          ],
        } as AllStaffbooksI;
      })
      .addCase(addStaffbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Staffbook
      .addCase(updateStaffbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStaffbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allStaffBooks = {
          ...state.allStaffBooks,
          data: state.allStaffBooks?.data.map((staffbook) =>
            staffbook.id === action.payload.result.staffbook.id
              ? action.payload.result.staffbook
              : staffbook,
          ) as StaffBookI[],
        } as AllStaffbooksI;
      })
      .addCase(updateStaffbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Staffbook
      .addCase(deleteStaffbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStaffbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allStaffBooks = {
          ...state.allStaffBooks,
          data: state.allStaffBooks?.data.filter(
            (staffbook) => staffbook.id !== action.payload.deletedId,
          ) as StaffBookI[],
        } as AllStaffbooksI;
      })
      .addCase(deleteStaffbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = staffbookSlice.actions;

export default staffbookSlice.reducer;
