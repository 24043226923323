export const formatLargeNumber = (value: number) => {
  const million = 1000000;
  const thousand = 1000;

  if (value >= million) {
    // Format as millions (e.g., 100M)
    return (value / million).toFixed(1) + "M";
  } else if (value >= thousand) {
    // Format as thousands (e.g., 100k)
    return (value / thousand).toFixed(0) + "K";
  } else {
    // Leave smaller values as they are
    return value.toString();
  }
};

/** This is add commas inside the amount figure */
export const formatNumbers = (number: number) => {
  return Intl.NumberFormat("en-US").format(number);
};
