import { Input } from "antd";

interface TextAreaI {
  label?: string;
  rows?: number;
  placeholder?: string;
}

const TextArea = ({ label, rows, placeholder, ...rest }: TextAreaI) => {
  return (
    <div className="custom-input">
      <label className="label">{label}</label>
      <Input.TextArea
        rows={rows ? rows : 4}
        placeholder={placeholder}
        className="text-area"
        size={"large" as any}
        {...rest}
      />
    </div>
  );
};

export { TextArea };
