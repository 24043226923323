import { createSlice } from "@reduxjs/toolkit";
import {
  addStockbook,
  deleteStockbook,
  getAllStockbooks,
  updateStockbook,
} from "./stockbook.service";
import { AddStockbookI, StockbookI } from "types";

export interface AllStockbooksI {
  data: StockbookI[];
  page: number;
  limit: number;
  hasNext: boolean;
  totalPages: number;
}

export interface StockbookStoreI {
  error: string | null;
  loading: boolean;
  allStockBooks: AllStockbooksI | null;
}

const initialState: StockbookStoreI = {
  loading: false,
  error: null,
  allStockBooks: {
    data: [],
    page: 1,
    limit: 10,
    hasNext: false,
    totalPages: 1,
  },
};

export const stockbookSlice = createSlice({
  name: "stockbook",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // get All Cashbooks
      .addCase(getAllStockbooks.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStockbooks.fulfilled, (state, action) => {
        state.loading = false;
        state.allStockBooks = action.payload.result;
      })
      .addCase(getAllStockbooks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Add New Customers
      .addCase(addStockbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(addStockbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allStockBooks = {
          ...state.allStockBooks,
          data: [
            action.payload.result.stockbook,
            ...(state.allStockBooks?.data as StockbookI[]),
          ],
        } as AllStockbooksI;
      })
      .addCase(addStockbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Update Stockbook
      .addCase(updateStockbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateStockbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allStockBooks = {
          ...state.allStockBooks,
          data: state.allStockBooks?.data.map((stockbook) =>
            stockbook.id === action.payload.result.stockbook.id
              ? action.payload.result.stockbook
              : stockbook,
          ) as StockbookI[],
        } as AllStockbooksI;
      })
      .addCase(updateStockbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Delete Stockbook
      .addCase(deleteStockbook.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteStockbook.fulfilled, (state, action) => {
        state.loading = false;
        state.allStockBooks = {
          ...state.allStockBooks,
          data: state.allStockBooks?.data.filter(
            (stockbook) => stockbook.id !== action.payload.deletedId,
          ) as StockbookI[],
        } as AllStockbooksI;
      })
      .addCase(deleteStockbook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = stockbookSlice.actions;

export default stockbookSlice.reducer;
