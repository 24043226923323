import "styles/app.css";
import { createBrowserRouter } from "react-router-dom";
import Login from "./pages/Login";
import DashboardLayout from "components/Layouts/DashboardLayout/DashboardLayout";
import Dashboard from "pages/Dashboard/Dashboard";
import * as Suppliers from "pages/Dashboard/Suppliers";
import * as Customers from "pages/Dashboard/Customers";
import AuthWrapper from "components/AuthWrapper";
import NotFoundPage from "pages/NotFoundPage";
import Notification from "pages/Notification/Notification";

const router = createBrowserRouter([
  {
    path: "/login",
    element: <Login />,
  },
  // Protected Routes
  {
    path: "/",
    element: (
      <AuthWrapper>
        <DashboardLayout />
      </AuthWrapper>
    ),
    children: [
      // dashboard route
      {
        path: "/",
        element: <Dashboard />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      // supplier routes
      {
        path: "suppliers/all-suppliers",
        element: <Suppliers.AllSuppliers />,
      },
      {
        path: "suppliers/bill-book",
        element: <Suppliers.BillBook />,
      },
      {
        path: "suppliers/cash-book",
        element: <Suppliers.CashBook />,
      },
      {
        path: "suppliers/staff-book",
        element: <Suppliers.StaffBook />,
      },
      {
        path: "suppliers/stock-book",
        element: <Suppliers.StockBook />,
      },
      {
        path: "supplier/stock-book/:id",
        element: <Suppliers.StockItemDetails />,
      },
      // customer routes
      {
        path: "customers/all-customers",
        element: <Customers.AllCustomers />,
      },
      {
        path: "customers/bill-book",
        element: <Customers.BillBook />,
      },
      {
        path: "customers/cash-book",
        element: <Customers.CashBook />,
      },
      {
        path: "customers/staff-book",
        element: <Customers.StaffBook />,
      },
      {
        path: "customers/stock-book",
        element: <Customers.StockBook />,
      },
      {
        path: "customers/stock-book/:id",
        element: <Customers.StockItemDetails />,
      },
      {
        path: "notifications",
        element: <Notification />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default router;
