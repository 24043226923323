import { useEffect, useRef, useState } from "react";
import { Button, Dropdown, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ChevronDown } from "assets/icons";
import { TableHeader } from "components/Common";
import { COLUMNS, CUSTOMER_ACTION_ITEMS as items } from "const";
import { getAllCustomers } from "store/features/customer/customer.service";
import { RootState, useAppDispatch, useAppSelector } from "store/store";
import { CustomerI } from "types";

interface CustomersTableI {
  onActionPress?: (type: { key: string }, data?: any) => void;
}

const CustomersTable = ({ onActionPress }: CustomersTableI) => {
  const dispatch = useAppDispatch();
  const tableContainerRef = useRef(null);
  const [type] = useState<string>("customer");
  const [activeRow, setActiveRow] = useState<CustomerI | null>(null);

  const { loading, allCustomers } = useAppSelector(
    (state: RootState) => state.customer
  );

  const querySearchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    dispatch(
      getAllCustomers({
        type,
        page: 1,
        limit: 10,
        search: e.currentTarget.value,
      })
    );
  };

  useEffect(() => {
    dispatch(getAllCustomers({ type, page: 1, limit: 10 }));
  }, [dispatch]);

  const menuProps = {
    items,
    onClick: (type: { key: string }) => {
      onActionPress && onActionPress(type, activeRow);
    },
  };

  const TABLE_COLUMNS: ColumnsType<CustomerI> = [
    {
      title: "Sr.",
      key: "serial",
      render: (text, record, index) => {
        const current = allCustomers?.page || 1;
        const pageSize = allCustomers?.limit || 10;
        const sr = (current - 1) * pageSize + index + 1;
        return sr;
      },
    },
    ...COLUMNS,
    {
      title: "Action",
      key: "action",
      render: (props) => (
        <Dropdown
          menu={menuProps as any}
          trigger={"click" as any}
          onOpenChange={(open: boolean) => {
            if (open) {
              setActiveRow(props);
            }
          }}
        >
          <Button className="action-btn">
            Actions
            <img src={ChevronDown} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <div className="page-padding display-center-col">
      <div className="table-container">
        <TableHeader
          data={allCustomers?.data}
          placeholder="Search Customers"
          onChange={querySearchHandler}
          tableContainerRef={tableContainerRef}
        />
        <div ref={tableContainerRef} className="overflow-y-auto">
          <Table
            loading={loading}
            columns={TABLE_COLUMNS}
            dataSource={allCustomers?.data}
            className="table"
            rowKey={(record) => record.id}
            pagination={{
              pageSize: allCustomers?.limit,
              total: allCustomers?.totalPages,
              onChange: (page, pageSize) => {
                dispatch(getAllCustomers({ type, page, limit: pageSize }));
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { CustomersTable };
