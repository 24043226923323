import "i18n/i18n";
import "styles/app.css";
import "styles/antd.css";
import React, { Suspense } from "react";
import { RouterProvider } from "react-router-dom";
import { ConfigProvider } from "antd";
import router from "./routes";
import { Provider } from "react-redux";
import { store, persistor } from "store/store";
import { PersistGate } from "redux-persist/integration/react";

const App: React.FC = () => (
  <div className="App">
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimaryBg: "#0C8CE9",
              colorPrimary: "black",

              fontFamily: 'Inter-Regular'
            },
          }}
        >
          <Suspense fallback={null}>
            <RouterProvider router={router} />
          </Suspense>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  </div>
);

export default App;
