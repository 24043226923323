import { ReactNode } from "react";

interface WidgetContainerI {
  title: string;
  headerRight?: ReactNode;
  children: ReactNode;
  width?: string;
  height?: string;
}

const WidgetContainer = ({
  title,
  headerRight,
  children,
  width,
  height,
  ...rest
}: WidgetContainerI) => {
  return (
    <div
      className={`${width ? width : "w-[100%]"} ${
        height ? height : "h-[500px] max-h-[500px]"
      } bg-greyBase py-4 rounded-xl border overflow-hidden`}
      {...rest}
    >
      <div className="flex justify-between items-center px-6">
        <h4 className="text-xl">{title}</h4>
        {headerRight}
      </div>
      <div className="w-full h-full mt-6">{children}</div>
    </div>
  );
};

export { WidgetContainer };
