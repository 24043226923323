import { Button, Dropdown, MenuProps, Table, message } from "antd";
import type { ColumnsType } from "antd/es/table";
import { ChevronDown } from "assets/icons";
import { TableHeader } from "components/Common";
import { STOCKBOOK_COLUMNS, STOCKBOOK_ACTION_ITEMS as items } from "const";
import { useEffect, useRef, useState } from "react";
import { getAllStockbooks } from "store/features/stockbook/stockbook.service";
import { RootState, useAppDispatch, useAppSelector } from "store/store";
import { StockbookI } from "types";

interface StockBookTableI {
  onActionPress?: (type: { key: string }, data?: any) => void;
  setStockOutModal: (open: boolean) => void;
  setStockInModal: (open: boolean) => void;
}

const StockBookTable = ({
  onActionPress,
  setStockOutModal,
  setStockInModal,
}: StockBookTableI) => {
  const dispatch = useAppDispatch();

  const tableContainerRef = useRef(null);
  const [type] = useState<string>("customer");
  const { allStockBooks, loading } = useAppSelector(
    (state: RootState) => state.stockbook
  );
  const [activeRow, setActiveRow] = useState<StockbookI | null>(null);

  useEffect(() => {
    dispatch(getAllStockbooks({ type, page: 1, limit: 10 }));
  }, [dispatch]);

  const menuProps = {
    items,
    onClick: (type: { key: string }) => {
      onActionPress && onActionPress(type, activeRow);
    },
  };

  const TABLE_COLUMNS: ColumnsType<StockbookI> = [
    {
      title: "Sr.",
      key: "serial",
      render: (text, record, index) => {
        const current = allStockBooks?.page || 1;
        const pageSize = allStockBooks?.limit || 10;
        const sr = (current - 1) * pageSize + index + 1;
        return sr;
      },
    },
    ...STOCKBOOK_COLUMNS,
    {
      title: "Action",
      key: "action",
      render: (props) => (
        <Dropdown
          menu={menuProps as any}
          trigger={"click" as any}
          onOpenChange={(open: boolean) => {
            if (open) {
              setActiveRow(props);
            }
          }}
        >
          <Button className="action-btn">
            Actions
            <img src={ChevronDown} />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const querySearchHandler = (e: React.FormEvent<HTMLInputElement>) => {
    dispatch(
      getAllStockbooks({
        type,
        page: 1,
        limit: 10,
        search: e.currentTarget.value,
      })
    );
  };

  return (
    <div className="page-padding display-center-col">
      <div className="table-container">
        <TableHeader
          onChange={querySearchHandler}
          data={allStockBooks?.data}
          tableContainerRef={tableContainerRef}
        />
        <div ref={tableContainerRef} className="overflow-y-auto">
          <Table
            loading={loading}
            columns={TABLE_COLUMNS}
            dataSource={allStockBooks?.data}
            className="customer-table"
            rowKey={(record) => record.id}
            pagination={{
              pageSize: allStockBooks?.limit,
              total: allStockBooks?.totalPages,
              onChange: (page, pageSize) => {
                dispatch(getAllStockbooks({ type, page, limit: pageSize }));
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { StockBookTable };
