import moment from "moment";
import { USAGE_DATA } from "const/widgets/usage";
import { WidgetContainer } from "../../Layouts/WidgetContainer/WidgetContainer";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useEffect, useState } from "react";
import { RevenueReportItemI } from "store/features/analytics/analytics.reducer";
import { formatLargeNumber } from "utils/number";

interface TotalSalesWidgetDataI {
  currYearData: RevenueReportItemI[];
  prevYearData: RevenueReportItemI[];
}

const TotalSalesWidget = ({
  label,
  data,
}: {
  label: string;
  data: TotalSalesWidgetDataI;
}) => {
  const [dataset, setDataset] = useState([]);
  const convertValue = (value: number) =>
    `Rs. ${formatLargeNumber(Math.round(value))}`;

  useEffect(() => {
    if (data && data?.currYearData?.length) {
      const combinedData = [];
      for (let i = 0; i < data.currYearData.length; i++) {
        const currYearItem = data.currYearData[i];
        const prevYearItem = data.prevYearData[i];

        const combinedItem = {
          date: currYearItem.date,
          currYearRevenue: currYearItem.revenue,
          prevYearRevenue: prevYearItem.revenue,
        };
        combinedData.unshift(combinedItem);
      }
      setDataset(combinedData as any);
    }
  }, [data]);

  return (
    <WidgetContainer title={label} headerRight={null}>
      <ResponsiveContainer width="100%" height={"80%"}>
        <ComposedChart data={dataset} margin={{ right: 30, left: 25 }}>
          <Legend verticalAlign="top" height={30} align="right" />
          <XAxis dataKey="date" stroke="grey" />
          <YAxis tickFormatter={convertValue} stroke="grey" />
          <CartesianGrid
            vertical={false}
            stroke="grey"
            strokeWidth={0.5}
            strokeOpacity={0.2}
          />
          <Tooltip
            labelFormatter={(label) => (
              <p className="text-base font-semibold">{label}</p>
            )}
            formatter={(value) => `Rs. ${value}`}
          />
          <Line
            name="Current Month"
            type="monotone"
            dataKey="currYearRevenue"
            stroke="#0C8CE9"
            strokeWidth={2}
            fill="transparent"
            activeDot={{ r: 8 }}
          />
          <Line
            name="Last Month"
            type="monotone"
            dataKey="prevYearRevenue"
            stroke="black"
            activeDot={{ r: 8 }}
            strokeWidth={2}
            fill="transparent"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </WidgetContainer>
  );
};

export { TotalSalesWidget };
