import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/httpService";
import { AddCustomerI, AddSupplierI, UpdateCustomerI, UpdateSupplierI } from "types";

export const getAllCustomers: any = createAsyncThunk(
  "customer/getAllCustomers",
  async (
    {
      page = 1,
      limit = 10,
      type,
      search = null,
    }: {
      page: number;
      limit: number;
      type: "customer" | "supplier";
      search: string | number | null;
    },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.get(
        `/customer/list?page=${page}&limit=${limit}${type ? "&type=" + type : ""}${search ? "&search=" + search : ""}`,
      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const addCustomer: any = createAsyncThunk(
  "customer/addCustomer",
  async (
    {
      data,
      type,
    }: { data: AddCustomerI | AddSupplierI; type?: "customer" | "supplier" },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.post(`/customer/create`, {
        ...data,
        type,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const updateCustomer: any = createAsyncThunk(
  "customer/updateCustomer",
  async (
    {
      id,
      data,
      type,
    }: { id: string; data: UpdateCustomerI | UpdateSupplierI; type: "customer" | "supplier" },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.patch(`/customer/${id}`, {
        ...data,
        type,
      });
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);

export const deleteCustomer: any = createAsyncThunk(
  "customer/deleteCustomer",
  async (
    {
      id,
      type = "customer",
    }: { id: string; data: UpdateCustomerI; type?: "customer" | "supplier" },
    { rejectWithValue }: any,
  ) => {
    try {
      const response = await axiosInstance.delete(`/customer/${id}`);
      return { ...response.data, deletedId: id };
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  },
);
