import { icons } from './index';

export type IconTypes = keyof typeof icons;

export interface IconProps {
  name?: IconTypes;
  src?: string;
  alt?: string;
  className?: string;
  onClick?: () => void;
}

const Icon = ({ name, src, alt = '', ...rest }: IconProps) => (
  <img src={name ? (icons[name] as string) : src} alt={alt} {...rest} />
);

export { Icon };
