import { ReactNode } from "react";

type HeaderI = {
  heading: string;
  children?: ReactNode;
};

const Header = ({ heading, children }: HeaderI) => {
  return (
    <div className="header">
      <h2 className="font font-InterSemiBold text-base sm:text-2xl">{heading}</h2>
      {children}
    </div>
  );
};

export { Header };
