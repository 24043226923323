export enum GTagEventType {
  ACCOUNT_LOGIN = "account_login",
  ACCOUNT_LOGOUT = "account_logout",

  ADD_CUSTOMER = "add_customer",
  UPDATE_CUSTOMER = "update_customer",
  DELETE_CUSTOMER = "delete_customer",
  
  ADD_SUPPLIER = "add_supplier",
  UPDATE_SUPPLIER = "update_supplier",
  DELETE_SUPPLIER = "delete_supplier",

  ADD_CASH = "add_cash",
  UPDATE_CASH = "update_cash",
  DELETE_CASH = "delete_cash",

  ADD_STOCK = "add_stock",
  UPDATE_STOCK = "update_stock",
  DELETE_STOCK = "delete_stock",

  ADD_BILL = "add_bill",

  ADD_STAFF = "add_staff",
  UPDATE_STAFF = "update_staff",
  DELETE_STAFF = "delete_staff",
}

export enum GTagEventCategory {
  ACCOUNT = "account",

  CUSTOMERS = "customers",
  SUPPLIERS = "suppliers",

  STOCKS_CUSTOMERS = "stockbook_customers",
  CASHBOOKS_CUSTOMERS = "cashbook_customers",
  STOCKBOOKS_CUSTOMERS = "stockbook_customers",
  BILLBOOKS_CUSTOMERS = "billbook_customers",
  STAFFBOOKS_CUSTOMERS = "staffbook_customers",

  STOCKS_SUPPLIERS = "stockbook_suppliers",
  CASHBOOKS_SUPPLIERS = "cashbook_suppliers",
  STOCKBOOKS_SUPPLIERS = "stockbook_suppliers",
  BILLBOOKS_SUPPLIERS = "billbook_suppliers",
  STAFFBOOKS_SUPPLIERS = "staffbook_suppliers",
}

export const sendGTagEvent = (label: string, category: string) => {
  //   window.gtag?.("event", "account_creation", {
  //     event_label: "new_account",
  //     event_category: "account",
  //   });

  window.gtag?.("event", label, {
    event_label: label,
    event_category: category,
  });
};
