import { Button, Dropdown } from "antd";
import { ChevronDown } from "assets/icons";
import { BillBookI } from "types";
import { getDateFormat } from "utils/date";
import { formatNumbers } from "utils/number";
import { capitalize } from "utils/text";
import { BILLBOOK_ACTION_ITEMS as items } from "const";

interface PropsI {
  item: BillBookI;
  onPress?: (type: string, data: any) => void;
}

const BillBookCard = ({ item, onPress }: PropsI) => {
  const menuProps = {
    items,
    onClick: ({ key }: { key: string }) => onPress && onPress(key, item),
  };

  return (
    <div className="w-[98%] m-auto px-[1.5rem] py-[12px] mb-4 flex justify-between items-center bg-white border border-stroke rounded-lg">
      <div className="max-w-[50%]">
        <h1 className="font-bold text-xl ">
          Bill # {String(item?.billNo)}
        </h1>
        <p className="text-grey ">
          {item?.date ? getDateFormat(item.date) : "-"}
        </p>

        <p className="truncate">Description: {item?.details}</p>
      </div>

      <div className="flex items-center gap-10">
        <div className="flex justify-center items-center gap-4 sm:gap-10">
          <p className="py-2 px-4 rounded-full bg-primary text-xs sm:text-sm text-white">
            {capitalize(item.billType)}
          </p>
          <span className="w-[80px] sm:w-[150px] font-bold text-sm sm:text-xl text-right">
            <h2>Rs {item?.amount ? formatNumbers(item.amount) : "0"}</h2>
          </span>
        </div>

        <Dropdown
          menu={menuProps as any}
          trigger={"click" as any}
        >
          <Button className="action-btn">
            Actions
            <img src={ChevronDown} />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
};

export { BillBookCard };
