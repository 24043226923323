import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "store/store";

type AuthProps = {
  children?: ReactNode;
};

const AuthWrapper: React.FC<AuthProps> = ({ children }) => {
  const { data } = useAppSelector((state) => state.user);
  return data?.token?.token ? <>{children}</> : <Navigate to={"/login"} />;
};

export default AuthWrapper;
