import * as yup from "yup";

export const createSupplierSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  phone: yup
    .string()
    .matches(/^\+923[0-9]{9}$/, "Invalid phone number")
    .required("Phone is required"),
  companyName: yup.string().required("Company name is required"),
  cnic: yup.string().length(15).required("CNIC is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  address: yup.string().required("Location is required"),
  details: yup.string().optional(),
});
