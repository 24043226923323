import "styles/app.css";
import BudgyLogo from "assets/images/Logo.svg";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Layout, Menu as AntDMenu, Input, message, Avatar, Modal } from "antd";
import type { MenuProps } from "antd";
import { useAppDispatch } from "store/store";
import {
  ExclamationCircleOutlined,
  LogoutOutlined,
  PieChartOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { logoutUser } from "store/features/auth/auth.reducer";
import { CountryPicker } from "components/Elements";
import { Icon } from "components/Common";
import { GTagEventCategory, GTagEventType, sendGTagEvent } from "utils/gtag";
import BreadCrumbs from "components/BreadCrumbs";

const { Content, Footer } = Layout;

type MenuItem = Required<MenuProps>["items"][number];
const rootSubmenuKeys = ["sub1", "sub2"];

const DashboardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [modal, contextHolder] = Modal.useModal();

  const [showSearchModal, setShowSearchModal] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [openKeys, setOpenKeys] = useState<string[]>(["/dashboard"]);
  const [selectedMenu, setSelectedMenu] = useState<string>("/dashboard");

  function getItem(
    label: React.ReactNode,
    key: React.Key,
    target: string | null,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return {
      key,
      icon,
      onClick: () => {
        setSidebarOpen(false);
        !!target && navigate(target);
      },
      children,
      label,
    } as MenuItem;
  }

  const items: MenuItem[] = [
    getItem("Dashboard", "/dashboard", "/dashboard", <PieChartOutlined />),
    getItem("Customers", "sub1", null, <TeamOutlined />, [
      getItem(
        "All Customers",
        "/customers/all-customers",
        "/customers/all-customers"
      ),
      getItem("Cash Book", "/customers/cash-book", "customers/cash-book"),
      getItem("Stock Book", "/customers/stock-book", "customers/stock-book"),
      getItem("Bill Book", "/customers/bill-book", "customers/bill-book"),
      getItem("Staff Book", "/customers/staff-book", "customers/staff-book"),
    ]),
    getItem("Suppliers", "sub2", null, <TeamOutlined />, [
      getItem(
        "All Suppliers",
        "/suppliers/all-suppliers",
        "suppliers/all-suppliers"
      ),
      getItem("Cash Book", "/suppliers/cash-book", "suppliers/cash-book"),
      getItem("Stock Book", "/suppliers/stock-book", "suppliers/stock-book"),
      getItem("Bill Book", "/suppliers/bill-book", "suppliers/bill-book"),
      getItem("Staff Book", "/suppliers/staff-book", "suppliers/staff-book"),
    ]),
  ];

  const onOpenChange: MenuProps["onOpenChange"] = (keys) => {
    console.log("keys ==== ", keys);

    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const handleMenuClick = () => {
    // sending gTag event here...
    sendGTagEvent(GTagEventType.ACCOUNT_LOGOUT, GTagEventCategory.ACCOUNT);

    // Logging user out
    navigate("/login");
    dispatch(logoutUser());
  };

  const navNavigation = [{ name: "Logout", href: "/login" }];

  const logoutModal = () => {
    modal.confirm({
      title: "Are you sure you want to logout?",
      okText: "Logout",
      onOk: handleMenuClick,
      cancelText: "Cancel",
    });
  };

  return (
    <div>
      {contextHolder}
      {/* Static sidebar for mobile */}
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[101] lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>

                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-greyBase">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      src={BudgyLogo}
                      alt="budgy-logo"
                      style={{
                        width: "60%",
                        marginTop: 20,
                        marginLeft: "20%",
                        marginBottom: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <AntDMenu
                      theme="light"
                      mode="inline"
                      items={items}
                      onSelect={(e) => setSelectedMenu(e.keyPath[0] as string)}
                      selectedKeys={[selectedMenu]}
                      className="bg-greyBase select-none"
                      style={{ borderRight: 0 }}
                    />
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col bg-greyBase">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 px-2 pb-4">
          <div className="flex mt-[24px] shrink-0 items-center">
            <img
              src={BudgyLogo}
              alt="budgy-logo"
              style={{
                width: "60%",
                marginLeft: "20%",
                marginBottom: "20px",
              }}
              className="cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          <nav className="flex flex-col">
            <AntDMenu
              theme="light"
              mode="inline"
              items={items}
              onSelect={(e) => setSelectedMenu(e.keyPath[0] as string)}
              selectedKeys={[selectedMenu]}
              className="bg-greyBase select-none"
              style={{ borderRight: 0 }}
            />
          </nav>

          <div className="flex justify-center mt-32">
            <div className="w-[80%] h-[205px] rounded-xl bg-primary px-4">
              <img src={BudgyLogo} alt="Logo" className="mx-auto mt-8" />
              <p className="text-white my-6 font-InterSemiBold text-center">
                Get access to all updated features
              </p>
              <div className="flex justify-center">
                <button
                  onClick={() =>
                    message.info(
                      "This feature is under-development. Coming Soon!"
                    )
                  }
                  className=" bg-white px-6 py-2 rounded-lg"
                >
                  Subscribe
                </button>
              </div>
            </div>
          </div>

          <div className="flex flex-1 justify-center items-end pb-10">
            <div
              onClick={() => logoutModal()}
              className="w-[80%] bg-greyBase hover:bg-stroke rounded-lg px-5 py-2 flex justify-start items-center gap-4 select-none cursor-pointer"
            >
              <LogoutOutlined size={20} />
              <span>Logout</span>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-50 border-b border-gray-200 shadow-sm bg-white lg:bg-greyBase">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 px-4 sm:gap-x-6 sm:px-6 lg:px-8">
            <div className="hidden lg:block">
              <BreadCrumbs />
            </div>
            {/* Open Drawer Toggle Button */}
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden "
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-200 lg:hidden"
              aria-hidden="true"
            />

            <img
              src={BudgyLogo}
              alt="budgy-logo"
              style={{
                width: 120,
              }}
              className="block lg:hidden cursor-pointer"
              onClick={() => navigate("/dashboard")}
            />

            <div className="flex flex-1 justify-end gap-x-4 self-stretch lg:gap-x-6">
              <div className="flex items-center gap-x-2 lg:gap-x-4">
                <div className="hidden lg:flex justify-center gap-4 searchField">
                  <CountryPicker />
                  <Input
                    value={""}
                    placeholder="Search here..."
                    prefix={<Icon name="search" />}
                    suffix={<Icon name="commandSlash" />}
                    className="w-48 bg-stroke select-none font-InterRegular"
                    onClick={() => setShowSearchModal(true)}
                  />
                </div>

                <Icon
                  name="magnifier"
                  className="w-9 cursor-pointer p-2 block lg:hidden"
                />
                <Icon
                  name="bell"
                  className="w-9 cursor-pointer p-2"
                  onClick={() => {
                    setSelectedMenu("/notifications");
                    navigate("/notifications");
                  }}
                />

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    {/* <span className="sr-only">Open user menu</span> */}
                    <Avatar size="large" icon={<UserOutlined />} />
                    <span className="hidden lg:flex lg:items-center">
                      <ChevronDownIcon
                        className="ml-2 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {navNavigation.map((item) => (
                        <span
                          key={item.name}
                          onClick={handleMenuClick}
                          className="block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer hover:bg-stroke"
                        >
                          {item.name}
                        </span>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>

        <main className="h-full bg-greyBase overflow-hidden">
          <div className="block lg:hidden py-4 px-6 bg-white">
            <BreadCrumbs />
          </div>
          <Content
            style={{
              padding: "0 16px",
              backgroundColor: "white",
            }}
          >
            <Outlet />
          </Content>
        </main>
      </div>

      {/* <SearchModal
        open={showSearchModal}
        onCancel={() => {
          setShowSearchModal(false);
        }}
        onSearch={(href) => {
          setShowSearchModal(false);
          navigate(href);
        }}
      /> */}
    </div>
  );
};

export default DashboardLayout;
