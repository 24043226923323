import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../axios/httpService";

export const listAnalytics: any = createAsyncThunk(
  "analytics/listAnalytics",
  async (_, { rejectWithValue }: any) => {
    try {
      const response = await axiosInstance.get("/analytics/list");
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.data?.error);
    }
  }
);
