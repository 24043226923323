import { Select } from "antd";
import React, { InputHTMLAttributes } from "react";

interface OptionI {
  label: string;
  value: string;
}

interface CustomInputI {
  label?: string;
  placeholder?: string;
  value?: string;
  options: OptionI[];
}

const CustomSelect = ({ label, placeholder, value, options, ...rest }: CustomInputI) => {
  return (
    <div className="custom-input">
      <label className="label">{label}</label>
      <Select
        placeholder={placeholder}
        defaultValue={"kg" as any}
        value={value as any}
        options={options}
        style={{minHeight: '3rem'}}
        {...rest}
      />
    </div>
  );
};

export { CustomSelect };
